import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Button,
  Table,
  DatePicker,
  Tag,
  Tooltip,
  Modal,
  Steps,
  Alert,
  message,
  Result,
} from "antd";
import {
  CheckOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
  SendOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import dayjs from "dayjs";
import "dayjs/locale/pt-br"; // Importa o locale para português do Brasil
import locale from "antd/es/date-picker/locale/pt_BR"; // Importa o locale do Ant Design para português do Brasil
import moment from "moment";
import EditTemplate from "../EditTemplate/EditTemplate";

const ViewQuestionarios = () => {
  const {
    postVersaoRespostas,
    moduloMapeamento,
    delegarQuestionario,
    pageSizeBig,
    currentCliente,
    atualizaPrazoVersaoRespostas,
    userInfo,
    usuarios,
  } = React.useContext(GlobalContext) as GlobalContextType;
  const navigate = useNavigate();
  const params = useParams();

  const [showModalQuestionario, setShowModalQuestionario] =
    React.useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [route, setRoute] = useState(null);

  const [versao, setVersao] = useState<any>(null);
  const handleDateChange = (date: any, record: any) => {
    atualizaPrazoVersaoRespostas(record._id, date);

    setEditingKey("");
  };
  const novoQuestionario = async () => {
    params.qid && postVersaoRespostas(params.qid);
  };

  const columns = [
    {
      title: "Data de criação",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: any) => moment(new Date(date)).format("DD/MM/YYYY HH:mm"),
    },
    // {
    //   title: "Data de envio",
    //   dataIndex: "updated_at",
    //   key: "updated_at",
    //   render: (date: any, record: any) =>
    //     record.created_at !== date
    //       ? moment(new Date(date)).format("DD/MM/YYYY HH:mm")
    //       : "-",
    // },
    {
      title: "Prazo",
      dataIndex: "prazo",
      key: "prazo",
      render: (prazo: any, record: any) => {
        const isEditing = record.key === editingKey;

        if (
          userInfo?.grupos?.find(
            (gp: any) => gp.name !== "assinante" && gp.name !== "seusdados"
          )
        ) {
          return prazo !== null
            ? moment(new Date(prazo)).format("DD/MM/YYYY")
            : "-";
        }
        return isEditing ? (
          <DatePicker
            locale={locale}
            defaultValue={dayjs(new Date(prazo))}
            onChange={(date) => handleDateChange(date, record)}
          />
        ) : (
          <div>
            {prazo !== null
              ? moment(new Date(prazo)).format("DD/MM/YYYY")
              : "-"}
            <Button type="link" onClick={() => setEditingKey(record.key)}>
              Editar
            </Button>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: any) => {
        const usrRespondente = usuarios?.find((usr: any) =>
          usr.funcoesDetalhadas?.find(
            (fd: any) =>
              fd.funcao_id?._id === "67599007713e52e267417c65" && // responder-versao
              fd.reference_model_id === record._id
          )
        );

        let iconColor = "";
        let text = "";

        if (!status || status === "Pendente") {
          if (usrRespondente) {
            iconColor = "#89c5d9";
          } else {
            iconColor = "#b5b5b5";
          }
        } else if (
          status === "Aguardando respostas" ||
          status === "Concluído"
        ) {
          iconColor = "green";
        } else if (status === "Enviado") {
          iconColor = "#fcba03";
        }

        if (!status || status === "Pendente") {
          if (usrRespondente) {
            text = "Aguardando envio";
          } else {
            text = "Sem respondentes";
          }
        } else if (status === "Concluído") {
          text = "Concluído";
        } else if (status === "Enviado") {
          text = "Enviado";
        }

        if (
          (record.respostas ===
            moduloMapeamento.questionarios?.filter(
              (quest: any) => quest._id.toString() === params.qid
            )[0]?.qtd_respondentes ||
            record.respostas === record.qtd_respondentes) &&
          record.respostas > 0
        ) {
          text = "Concluído";
          iconColor = "green";
        }
        return (
          <Tag color={iconColor} style={{ fontWeight: "bold" }}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Respostas",
      dataIndex: "respostas",
      key: "respostas",
      render: (respostas: number, record: any) => {
        return (
          <Button type="link" onClick={() => console.log(record)}>
            {respostas} /{" "}
            {moduloMapeamento.questionarios?.filter(
              (quest: any) => quest._id.toString() === params.qid
            )[0]?.qtd_respondentes ||
              record?.qtd_respondentes ||
              0}
          </Button>
        );
      },
    },
    {
      title: "Linha do tempo",
      key: "_id",
      dataIndex: "_id",
      render: (id: string, record: any) => {
        const usrRespondente = usuarios?.find((usr: any) =>
          usr.funcoesDetalhadas?.find(
            (fd: any) =>
              fd.funcao_id?._id === "67599007713e52e267417c65" && // responder-versao
              fd.reference_model_id === record._id
          )
        );

        const concluido =
          (record.respostas ===
            moduloMapeamento.questionarios?.filter(
              (quest: any) => quest._id.toString() === params.qid
            )[0]?.qtd_respondentes ||
            record.respostas === record.qtd_respondentes) &&
          record.respostas > 0;

        let step = 0;

        if (!usrRespondente) {
          step = 0;
        }
        if (usrRespondente && record.status === "Pendente") {
          step = 1;
        }
        if (record.status === "Enviado") {
          step = 2;
        }
        if (concluido) {
          step = 3;
        }

        return (
          <Steps
            style={{ marginTop: 8 }}
            size="small"
            progressDot
            current={step}
            items={[
              {
                title: (
                  <Button
                    type={step === 0 ? "primary" : "default"}
                    size="small"
                    onClick={() => {
                      setVersao(record);
                      setIsModalOpen(true);
                    }}
                    icon={
                      usrRespondente ? <CheckOutlined /> : <UserAddOutlined />
                    }
                  >
                    Respondentes
                  </Button>
                ),
              },
              {
                title: (
                  <Tooltip
                    title={
                      record.created_at !== record.updated_at
                        ? "Último envio " +
                          moment(new Date(record.updated_at)).format(
                            "DD/MM/YYYY HH:mm"
                          )
                        : ""
                    }
                  >
                    <Button
                      size="small"
                      type={step === 1 ? "primary" : "default"}
                      onClick={() =>
                        moduloMapeamento.sendQuestionarios(
                          record,
                          currentCliente._id
                        )
                      }
                      icon={
                        record.status === "Enviado" ? (
                          <CheckOutlined />
                        ) : (
                          <SendOutlined />
                        )
                      }
                    >
                      {record.status === "Enviado"
                        ? "Enviar novamente"
                        : "Enviar"}
                    </Button>
                  </Tooltip>
                ),
              },
              {
                description: (
                  <div
                    style={{
                      fontSize: 12,
                      width: concluido
                        ? "9.5rem"
                        : step === 2
                        ? "9.6rem"
                        : "8.5rem",
                      color: step === 2 ? "#5f29cc" : "#000",
                      marginTop: "3.5px",
                    }}
                  >
                    {concluido && (
                      <CheckOutlined style={{ marginRight: "0.5rem" }} />
                    )}
                    {step === 2 && (
                      <LoadingOutlined style={{ marginRight: "0.5rem" }} />
                    )}
                    Aguardando respostas
                  </div>
                ),
              },
              {
                title: (
                  <Button
                    size="small"
                    type={concluido ? "primary" : "default"}
                    icon={<EyeOutlined />}
                    onClick={() => {
                      navigate(`/analise/${params.qid}/versao/${id}`);
                    }}
                  >
                    Análise
                  </Button>
                ),
              },
            ]}
          />
        );
      },
    },
    // {
    //   title: "Ações",
    //   dataIndex: "_id",
    //   key: "_id",
    //   render: (id: String, record: any) => {
    //     const isDisabled = userInfo?.grupos?.find(
    //       (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
    //     )
    //       ? false
    //       : !(record.respostas && record.respostas > 0);

    //     return (
    //       <div>
    //         <Button
    //           type="primary"
    //           onClick={() => setIsModalOpen(true)}
    //           icon={<UsergroupAddOutlined />}
    //           style={{ marginRight: "1rem" }}
    //         >
    //           Adicionar respondentes
    //         </Button>
    //         <Button
    //           onClick={() =>
    //             moduloMapeamento.sendQuestionarios(record, currentCliente._id)
    //           }
    //           icon={<SendOutlined />}
    //           style={{ marginRight: "1rem" }}
    //         >
    //           {record.status === "Enviado"
    //             ? "Enviar novamente"
    //             : "Enviar para respondentes"}
    //         </Button>
    //         <>
    //           <Tooltip
    //             title={
    //               isDisabled
    //                 ? "Você precisa ter mais de uma resposta para visualizar a análise."
    //                 : ""
    //             }
    //           >
    //             <Button
    //               disabled={isDisabled}
    //               type="default"
    //               className="btn-success"
    //               htmlType="submit"
    //               icon={<EyeOutlined />}
    //               onClick={() => {
    //                 navigate(`/analise/${params.qid}/versao/${id}`);
    //               }}
    //             >
    //               Análise
    //             </Button>
    //           </Tooltip>
    //         </>
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    !currentCliente && navigate("/dashboardinicial");
  }, [currentCliente]);

  return (
    <div className="viewQuestionario">
      <BackHeader
        title={
          moduloMapeamento.questionarios?.filter(
            (q: any) => q._id === params.qid
          )[0]?.nome || ""
        }
        prev
      />

      <>
        <div style={{ marginBottom: "1rem" }}>
          {userInfo?.grupos?.find(
            (gp: any) =>
              gp.name === "assinante" ||
              gp.name === "seusdados" ||
              gp.name === "sponsor"
          ) && (
            <Button
              type="primary"
              onClick={() => novoQuestionario()}
              icon={<PlusOutlined />}
            >
              Nova aplicação
            </Button>
          )}
        </div>

        <Table
          size="small"
          dataSource={moduloMapeamento.questionarios
            ?.find(
              (quest: any) =>
                quest._id.toString() === params.qid && quest.versoes[0]
            )
            ?.versoes.sort(
              (a: any, b: any) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )}
          pagination={{ pageSize: pageSizeBig }}
          columns={columns}
        />
      </>

      <Modal
        open={isModalOpen}
        onCancel={() => {
          setVersao(null);
          setIsModalOpen(false);
        }}
        title="Quem irá responder este questionário?"
        footer={[]}
        width={1200}
      >
        <br />
        {userInfo?.grupos?.find(
          (gp: any) => gp.name === "seusdados" || gp.name === "sponsor"
        ) && (
          <>
            <Alert
              type="info"
              showIcon
              message={`Explicação sobre botão "Quero responder".`}
            />
          </>
        )}
        <Tooltip
          title={
            userInfo?.grupos?.find(
              (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
            )
              ? "Disponível somente para o cliente"
              : ""
          }
        >
          <Button
            style={{
              marginBottom: "1rem",
              marginTop: "1rem",
              // background: "green",
            }}
            type="primary"
            onClick={() => {
              const currentQuestionarios = moduloMapeamento.questionarios?.find(
                (q: any) => q._id === params.qid
              );

              // Delegar questionário todo para usuário atual

              if (currentQuestionarios) {
                setLoading(true);
                delegarQuestionario({
                  versao_id: versao?._id,
                  ...currentQuestionarios,
                  lider_areas: [
                    {
                      user_id: userInfo._id,
                      area_id: currentQuestionarios.areas?.[0],
                      questionario_id: currentQuestionarios._id,
                      questionario_nome: currentQuestionarios.nome,
                    },
                  ],
                  lider_processos: [],
                }).then(() => {
                  moduloMapeamento
                    .sendQuestionarios(versao, currentCliente._id)
                    .then((data) => {
                      setRoute(data.route);
                      setIsModalOpen(false);
                      setShowModalQuestionario(true);
                    })
                    .finally(() => setLoading(false));
                });
              } else {
                message.error("Falha ao encontrar questionário");
              }
            }}
            icon={<EditOutlined />}
            loading={loading}
            disabled={
              userInfo?.grupos?.find(
                (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
              )
                ? true
                : false
            }
          >
            Quero responder
          </Button>
        </Tooltip>

        <EditTemplate mode="delegar" versaoId={versao?._id} />
      </Modal>

      <Modal
        title={""}
        open={showModalQuestionario}
        footer={null}
        width={"500px"}
        onCancel={() => setShowModalQuestionario(false)}
      >
        <Result
          status="success"
          icon={
            <FileDoneOutlined style={{ fontSize: "80px", color: "#32E6BE" }} />
          }
          title="Questionário disponível!"
          subTitle={`O questionário já está pronto para ser respondido! Para facilitar, enviamos também um link de acesso ao seu e-mail, caso precise acessá-lo posteriormente.`}
          extra={[
            <Button
              onClick={() => {
                route && navigate(route);
              }}
              type="primary"
              style={{ background: "#008C76" }}
            >
              <div className="d-flex j-c">
                <div>Responder já </div>
                <RightOutlined style={{ marginLeft: "0.5rem" }} />
              </div>
            </Button>,
          ]}
        />
      </Modal>
    </div>
  );
};

export default ViewQuestionarios;
