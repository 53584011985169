import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Modal,
  Form,
  Select,
  message,
  DatePicker,
  Tag,
  Row,
  Col,
} from "antd";

import { GlobalContextType } from "../../../@types/global";
import { GlobalContext } from "../../../context/globalContext";
import "./styles.css";
import { useNavigate } from "react-router-dom";

import { ModalCriarTarefa } from "../../../@types/modalCriarTarefa";
import ReactQuill from "react-quill";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";

const CriarTarefa: React.FC<ModalCriarTarefa> = ({
  showModal,
  handleShowModal,
  tipo,
  modelo_referencia_id,
  reuniaoId,
  pautaId,
}) => {
  const { moduloTask, usuarios, currentCliente, clientes } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [form] = Form.useForm();

  const [comment, setComment] = useState<string>("");
  const [departamentos, setDepartamentos] = useState<any[] | null>(null);
  const [categorias, setCategorias] = useState<any[] | null>(null);
  const [currentDep, setCurrentDep] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [clienteSelecionado, setClienteSelecionado] = useState<any>(null);

  const onFinish = (v: any) => {
    setLoading(true);
    moduloTask
      .criarTarefa(
        tipo,
        {
          ...v,
          ...(modelo_referencia_id
            ? { modelo_referencia_id: modelo_referencia_id }
            : {}),
          ...(pautaId ? { dependencia: pautaId } : {}),
        },
        currentCliente?._id || clienteSelecionado
      )
      .then((r) => {
        let texto = "Item criado com sucesso!";

        switch (tipo) {
          case "tarefa-pauta":
            texto = "Tarefa criada com sucesso!";
            break;
          case "chamado":
            texto = "Chamado criado com sucesso!";
            break;
          case "pauta":
            texto = "Pauta criada com sucesso!";
            break;
          case "reuniao":
            texto = "Tarefa criada com sucesso!";
            break;
          default:
            texto = "Tarefa criada com sucesso!";
            break;
        }

        message.success(texto);
      })
      .finally(() => {
        form.resetFields();
        setComment("");
        if (currentCliente?._id) {
          moduloTask.fetchClienteTasks(currentCliente?._id);
        } else {
          moduloTask.fetchTasks();
        }
        setLoading(false);
        setClienteSelecionado(null);

        handleShowModal();
      });
  };

  const onChangeDep = (v: any) => {
    setCurrentDep(
      departamentos?.find((d: any) => d.id_tom.toString() === v)._id
    );
  };

  useEffect(() => {
    if (showModal) {
      moduloTask.getDepartamentos().then((r) => setDepartamentos(r));
      moduloTask.getCategorias().then((r) => setCategorias(r));
    }
  }, [showModal]);

  const disabledDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  return (
    <>
      <Modal
        title={
          tipo === "tarefa-pauta"
            ? "Nova Tarefa"
            : tipo === "pauta"
            ? "Nova pauta"
            : tipo === "tarefa-comum"
            ? "Nova tarefa"
            : "Novo chamado"
        }
        open={showModal}
        footer={null}
        onCancel={handleShowModal}
        width={700}
      >
        <Form
          // {...layoutForn}
          layout="vertical"
          form={form}
          name="adiciona_fornecedor"
          onFinish={onFinish}
          autoComplete="off"
          style={{
            marginTop: "2rem",
            maxHeight: "70vh",
            overflowY: "auto",
            paddingRight: "0.5rem",
          }}
        >
          {!currentCliente?._id && tipo === "tarefa-comum" && (
            <Form.Item label="Cliente" required>
              <Select
                allowClear
                placeholder="Selecione um cliente"
                showSearch
                onChange={(v: string) => setClienteSelecionado(v)}
                optionFilterProp="filter"
                filterOption={(input: any, option: any) =>
                  option?.filter.toLowerCase().includes(input.toLowerCase())
                }
                options={clientes
                  ?.filter(
                    (c: any) =>
                      c.cliente_sup || c._id === "64e648ff902fd5e820397ebb" // Seusdados
                  )
                  ?.filter(
                    (c: any) =>
                      c.cliente_sup === "64e648ff902fd5e820397ebb" || // Seusdados
                      c._id === "64e648ff902fd5e820397ebb" // Seusdados
                  )
                  ?.sort((a: any, b: any) => {
                    // Garantir que o item com razao_social === "Apresentação" seja o primeiro
                    if (a.label === "Apresentação") return -1;
                    if (b.label === "Apresentação") return 1;
                    return a?.label?.localeCompare(b?.label);
                  })
                  ?.map((cli: any) => {
                    if (cli._id === "64e648ff902fd5e820397ebb") {
                      return {
                        value: cli._id,
                        filter: cli.razao_social,
                        label: (
                          <span
                            style={{ color: "#5f29cc", fontWeight: "bold" }}
                          >
                            {cli.razao_social}
                          </span>
                        ),
                      };
                    }
                    return {
                      value: cli._id,
                      filter: cli.razao_social,
                      label: cli.razao_social,
                    };
                  })}
              />
            </Form.Item>
          )}
          <Form.Item label="Assunto" name="title" required>
            <Input />
          </Form.Item>
          {tipo === "chamado" && (
            <Form.Item label="Departamento" name="departamento">
              <Select
                allowClear
                placeholder="Selecione um departamento"
                optionFilterProp="children"
                onChange={onChangeDep}
                // onSearch={onSearch}
                // filterOption={filterOption}
                options={departamentos?.map((dep: any) => {
                  return {
                    value: dep.id_tom,
                    label: dep.nome,
                  };
                })}
              />
            </Form.Item>
          )}

          {tipo === "tarefa-pauta" && !pautaId && (
            <Form.Item
              label="Deseja associar a uma pauta?"
              name={"dependencia"}
              required
            >
              <Select
                allowClear
                placeholder="Selecione uma pauta para associar"
                optionFilterProp="children"
                onChange={(e) => console.log(e)}
                // onSearch={onSearch}
                // filterOption={filterOption}
                options={moduloTask?.tasksCliente
                  ?.filter(
                    (task) =>
                      task.tipo === "pauta" &&
                      task.modelo_referencia_id === reuniaoId
                  )
                  .map((task: any) => {
                    return {
                      value: task._id,
                      label: task.title,
                    };
                  })}
              />
            </Form.Item>
          )}

          {currentDep && (
            <Form.Item label="Categoria" name="categoria" required>
              <Select
                allowClear
                placeholder="Selecione uma categoria"
                optionFilterProp="children"
                // onChange={onChange}
                // onSearch={onSearch}
                // filterOption={filterOption}
                options={categorias
                  ?.filter(
                    (cat: any) => cat.departamento_id.toString() === currentDep
                  )
                  .map((cat: any) => {
                    return {
                      value: cat.id_tom,
                      label: cat.nome,
                    };
                  })}
              />
            </Form.Item>
          )}

          <Form.Item
            label={tipo === "chamado" ? "Mensagem" : "Descrição"}
            name="description"
            required
          >
            <ReactQuill
              placeholder="Escreva sua resposta"
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                ],
                clipboard: {
                  matchVisual: false,
                },
              }}
              value={comment}
              onChange={(v) => setComment(v)}
            />
          </Form.Item>

          {tipo !== "chamado" && (
            <Form.Item
              name="prazo_conclusao"
              label="Prazo de Conclusão"
              required
            >
              <DatePicker
                format="DD/MM/YYYY"
                className="w-full"
                disabledDate={disabledDate}
              />
            </Form.Item>
          )}
          {tipo !== "chamado" && (
            <Form.Item name="assignment_id" label="Atribuição" required>
              <Select
                placeholder="Selecione um usuário"
                optionFilterProp="children"
                options={[
                  ...(usuarios?.filter(
                    (usr: any) => usr.cliente_id === currentCliente?._id
                    // &&
                    //   usr.grupos
                    //     ?.map((g: any) => g._id)
                    //     .some((grupo: string) => gruposDoComite.includes(grupo))
                  ) || []),
                  ...(usuarios?.filter(
                    (usr: any) =>
                      usr.grupos.find(
                        (gp: any) =>
                          gp.name === "assinante" || gp.name === "seusdados"
                      ) && usr.cliente_id === "64e648ff902fd5e820397ebb" // somente da Seusdados
                  ) || []),
                ]?.map((user: any) => {
                  return {
                    value: user._id,
                    label: (
                      <>
                        <span style={{ marginRight: "1rem" }}>{user.name}</span>
                        {user?.grupos?.map((gp: any) => (
                          <Tag
                            color={
                              gp.name === "assinante" || gp.name === "seusdados"
                                ? "purple"
                                : "blue"
                            }
                            className="Header__right--nivel"
                          >
                            {gp.name
                              .replace("assinante", "consultor")
                              .toUpperCase()}
                          </Tag>
                        ))}
                      </>
                    ),
                  };
                })}
              />
            </Form.Item>
          )}
          {tipo === "chamado" && (
            <Form.Item label="Prioridade" name="prioridade" required>
              <Select
                placeholder="Selecione a prioridade"
                optionFilterProp="children"
                defaultValue={1}
                // onChange={onChange}
                // onSearch={onSearch}
                // filterOption={filterOption}

                options={[1, 2, 3, 4].map((item: any) => {
                  let label;

                  switch (item) {
                    case 1:
                      label = "Baixa (Padrão)";
                      break;
                    case 2:
                      label = "Normal";
                      break;
                    case 3:
                      label = "Alta";
                      break;
                    case 4:
                      label = "Urgente";
                      break;

                    default:
                      label = "Baixa (Padrão)";
                      break;
                  }

                  return {
                    value: item,
                    label: label,
                  };
                })}
              />
            </Form.Item>
          )}

          <Form.Item
            style={{
              justifyContent: "flex-end",
              display: "flex",
            }}
          >
            <Button
              loading={loading}
              type="primary"
              size="large"
              htmlType="submit"
              icon={<PlusOutlined />}
            >
              {tipo === "tarefa-comum" && "Criar tarefa"}
              {tipo === "tarefa-pauta" && "Criar tarefa"}
              {tipo === "chamado" && "Criar chamado"}
              {tipo === "pauta" && "Criar pauta"}
              {tipo === "reuniao" && "Criar tarefa"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CriarTarefa;
