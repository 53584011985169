import React, { useEffect, useState } from "react";
import {
  Upload,
  Button,
  Table,
  Collapse,
  Empty,
  Tag,
  Select,
  Alert,
  Row,
  Col,
  Tabs,
} from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  RobotOutlined,
  SaveOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { useParams } from "react-router-dom";
import moment from "moment";
import "./styles.css";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { Cell, Legend, Pie, PieChart } from "recharts";

export type ResultadoCsa = {
  _id: { $oid: string };
  resume: string;
  suggestionsForCorrection: string;
  bestPractices: string;
  host: string;
  ip: string;
  port: string;
  severity: "high" | "medium" | "low" | "info";
  cve: string;
  cwe: string;
  cvssMetrics: string;
  cvssScore: string;
  tags: string;
  reference: string;
  cliente_id: { $oid: string };
  anexo_id: { $oid: string };
  updated_at: { $date: string };
  created_at: { $date: string };
};

type SeverityCounts = {
  high: number;
  medium: number;
  low: number;
  info: number;
};

type DashboardItemProps = {
  title: string;
  text: string | number;
  textSize?: number;
};

const DashboardItem: React.FC<DashboardItemProps> = ({
  title,
  text,
  textSize,
}) => {
  return (
    <div className="dashboard_item">
      <h4>{title}</h4>
      <p style={textSize ? { fontSize: textSize, marginBottom: 10 } : {}}>
        {text}
      </p>
    </div>
  );
};

const OWASP = () => {
  const {
    postAnexoOwasp,
    getAnexosOwasp,
    anexosOwasp,
    userInfo,
    clientes,
    usuarios,
    currentCliente,
    moduloCsa,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const params = useParams();

  const [importedData, setImportedData] = useState<any>(null);
  const [currentRelatorio, setCurrentRelatorio] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [resultadosCsa, setResultadosCsa] = useState<any>(null);

  // NUCLEI
  const [quantidadeIps, setQuantidadeIps] = useState<number | null>(null);
  const [quantidadePortas, setQuantidadePortas] = useState<number | null>(null);
  const [itensPorSeveridade, setItensPorSeveridade] =
    useState<SeverityCounts | null>(null);

  useEffect(() => {
    (params.id || currentCliente) &&
      getAnexosOwasp(params.id || currentCliente._id);

    !params.id &&
      clientes &&
      clientes[0] &&
      clientes.length === 1 &&
      getAnexosOwasp(clientes[0]._id);
  }, [params.id, clientes, currentCliente]);

  const handleImport = async (file: any) => {
    try {
      setFile(file);
      const fileContent: any = await readFile(file);
      const jsonData = JSON.parse(fileContent);
      setImportedData(jsonData);
    } catch (error) {
      console.error("Erro ao processar o arquivo JSON:", error);
    }
  };

  const readFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        resolve(e.target.result);
      };

      reader.onerror = (e: any) => {
        reject(e.target.error);
      };

      reader.readAsText(file);
    });
  };

  const columns = [
    {
      title: "Nome do program",
      dataIndex: "@programName",
      key: "@programName",
      render: (text: string) => text,
    },
    {
      title: "Versão",
      dataIndex: "@version",
      key: "@version",
      render: (text: string) => text,
    },
    {
      title: "Data geração",
      dataIndex: "@generated",
      key: "@generated",
      render: (text: string) => text,
    },
  ];

  function formatJSON(input: any) {
    // Adiciona colchetes para criar um array válido e insere vírgulas entre as entradas.
    const fixedJSON = `[${input.replace(/}\s*{/g, "},{")}]`;

    try {
      // Analisa o JSON corrigido.
      const jsonArray = JSON.parse(fixedJSON);
      return jsonArray;
    } catch (error) {
      console.error("Erro ao formatar o JSON:", error);
      return null;
    }
  }

  const downloadAndReadFile = async (url: string) => {
    setCurrentRelatorio(null);
    try {
      // Fazer o Download do Arquivo
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Erro ao baixar o arquivo");
      }

      // Ler o Conteúdo do Arquivo como ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();

      // Converter ArrayBuffer para JSON
      const jsonString = new TextDecoder().decode(arrayBuffer);

      let jsonData;
      if (url.includes("csa-analises")) {
        jsonData = formatJSON(jsonString);
      } else {
        jsonData = JSON.parse(jsonString);
      }

      setCurrentRelatorio(jsonData);
      setResultadosCsa(null);
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  const colors: Record<keyof SeverityCounts, string> = {
    high: "red", // Vermelho mais escuro
    medium: "orange", // Laranja mais escuro
    low: "green", // Verde mais escuro
    info: "#5f29cc", // Azul mais escuro
  };
  const severityMap: Record<string, keyof SeverityCounts> = {
    high: "high",
    critical: "high",
    alto: "high",
    severe: "high",
    medium: "medium",
    médio: "medium",
    low: "low",
    baixa: "low",
    minor: "low",
    info: "info",
    informação: "info",
  };
  // Mapeamento das severidades para legendas em português
  const severityLabels: Record<keyof SeverityCounts, string> = {
    high: "ALTA",
    medium: "MÉDIO",
    low: "BAIXA",
    info: "INFO",
  };

  const normalizeSeverity = (severity: string): keyof SeverityCounts => {
    return severityMap[severity?.toLowerCase()] || "info";
  };
  useEffect(() => {
    const processResults = (resultadosCsa: ResultadoCsa[]) => {
      // Quantidade de IPs únicos
      const uniqueIps = new Set(resultadosCsa.map((item) => item.ip));
      const uniqueIpsCount: number = uniqueIps.size;

      // Quantidade de portas únicas descobertas por IP
      const uniquePortsByIp: Record<string, Set<string>> = {};
      resultadosCsa.forEach((item) => {
        if (!uniquePortsByIp[item.ip]) {
          uniquePortsByIp[item.ip] = new Set();
        }
        uniquePortsByIp[item.ip].add(item.port);
      });
      const totalUniquePorts: number = Object.values(uniquePortsByIp).reduce(
        (sum, ports) => sum + ports.size,
        0
      );

      // Contagem de itens por nível de severidade
      const severityCounts: SeverityCounts =
        resultadosCsa.reduce<SeverityCounts>(
          (acc, item) => {
            const normalizedSeverity = normalizeSeverity(item.severity);
            acc[normalizedSeverity] = (acc[normalizedSeverity] || 0) + 1;
            return acc;
          },
          { high: 0, medium: 0, low: 0, info: 0 }
        );

      setQuantidadeIps(uniqueIpsCount);
      setQuantidadePortas(totalUniquePorts);
      setItensPorSeveridade(severityCounts);
    };

    resultadosCsa !== null && processResults(resultadosCsa);
  }, [resultadosCsa]);

  return (
    <div className="owasp">
      {/* Introdução */}
      {!currentRelatorio && !resultadosCsa && (
        <>
          <BackHeader title="Resultados Pentest" />

          {userInfo?.grupos?.find(
            (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
          ) &&
            currentCliente && (
              <>
                <h3>Criar novo relatório</h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Upload
                    showUploadList={false}
                    beforeUpload={(file) => handleImport(file)}
                    accept=".json"
                  >
                    <Button
                      style={{
                        marginLeft: "-1.9rem",
                      }}
                      icon={<UploadOutlined style={{ color: "orange" }} />}
                    >
                      Enviar JSON
                    </Button>
                  </Upload>

                  {importedData && (
                    <div>
                      <Select
                        onChange={(v) => setSelectedUser(v)}
                        placeholder="Selecione um encarregado"
                        options={usuarios
                          ?.filter(
                            (usr: any) => usr.cliente_id === currentCliente?._id
                          )
                          ?.map((user: any) => {
                            return {
                              label: user.name,
                              value: user._id,
                            };
                          })}
                        style={{ marginRight: "1rem", width: 400 }}
                      />
                      <Button
                        type="primary"
                        disabled={selectedUser ? false : true}
                        onClick={() => {
                          (params.id || currentCliente?._id) &&
                            postAnexoOwasp(
                              file,
                              params.id || currentCliente._id,
                              selectedUser
                            ).finally(() => setImportedData(null));
                          setSelectedUser(null);
                        }}
                        icon={<SaveOutlined />}
                      >
                        Salvar relatório
                      </Button>
                    </div>
                  )}
                </div>

                {importedData && (
                  <div style={{ marginBottom: "1rem" }}>
                    <h4 style={{ marginBottom: "1rem" }}>Dados Importados:</h4>
                    <Table
                      style={{ marginBottom: "1rem" }}
                      pagination={false}
                      dataSource={[
                        {
                          "@programName": importedData["@programName"],
                          "@version": importedData["@version"],
                          "@generated": importedData["@generated"],
                        },
                      ]}
                      columns={columns}
                    />

                    <Collapse
                      items={importedData.site.map(
                        (site: any, index: number) => {
                          return {
                            key: index + 1,
                            label: `${site["@name"]} :${site["@port"]}`,
                            extra: site.alerts[0] ? (
                              <ExclamationCircleOutlined
                                style={{ color: "#fc0303" }}
                              />
                            ) : (
                              <CheckCircleOutlined
                                style={{ color: "#22c900" }}
                              />
                            ),
                            children: site.alerts[0] ? (
                              site.alerts.map((alerta: any, index2: number) => (
                                <Collapse
                                  items={[
                                    {
                                      key: index2 + 1 + alerta.name,
                                      label: `${alerta.name}`,
                                      extra: (
                                        <p
                                          style={{
                                            color: alerta.riskdesc.includes(
                                              "Informativo"
                                            )
                                              ? "#0054d1"
                                              : alerta.riskdesc.includes(
                                                  "Baixo ("
                                                )
                                              ? "#ffb638"
                                              : alerta.riskdesc.includes(
                                                  "Médio ("
                                                )
                                              ? "#ff6a00"
                                              : "#fc0303",
                                          }}
                                        >
                                          {alerta.riskdesc}
                                        </p>
                                      ),
                                      children: (
                                        <>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: alerta.desc,
                                            }}
                                          />
                                          <br />
                                          <h4>Recomendação</h4>
                                          <div
                                            style={{ marginLeft: "1rem" }}
                                            dangerouslySetInnerHTML={{
                                              __html: alerta.solution,
                                            }}
                                          />
                                          <br />
                                          <Collapse
                                            items={[
                                              {
                                                key: index2 + alerta.desc,
                                                label: `Instâncias (${alerta.instances.length})`,
                                                children: (
                                                  <Collapse
                                                    items={alerta.instances.map(
                                                      (
                                                        instance: any,
                                                        index3: number
                                                      ) => {
                                                        return {
                                                          key:
                                                            index3 +
                                                            instance.uri,
                                                          label: (
                                                            <div
                                                              style={{
                                                                display: "flex",
                                                              }}
                                                            >
                                                              <Tag
                                                                color={
                                                                  instance.method ===
                                                                  "GET"
                                                                    ? "green"
                                                                    : instance.method ===
                                                                      "POST"
                                                                    ? "#0054d1"
                                                                    : ""
                                                                }
                                                              >
                                                                GET
                                                              </Tag>
                                                              <p>
                                                                {instance.uri}
                                                              </p>
                                                            </div>
                                                          ),
                                                          children: (
                                                            <>
                                                              <h4>
                                                                Evidencia:
                                                              </h4>
                                                              <p
                                                                style={{
                                                                  marginLeft:
                                                                    "1rem",
                                                                }}
                                                              >
                                                                {
                                                                  instance.evidence
                                                                }
                                                              </p>
                                                              <br />
                                                              <h4>
                                                                Outras
                                                                Informações
                                                              </h4>
                                                              <div
                                                                style={{
                                                                  marginLeft:
                                                                    "1rem",
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    instance.otherinfo,
                                                                }}
                                                              />
                                                            </>
                                                          ),
                                                        };
                                                      }
                                                    )}
                                                  />
                                                ),
                                              },
                                            ]}
                                          />
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                              ))
                            ) : (
                              <Empty description="Nenhuma falha de segurança detectada" />
                            ),
                          };
                        }
                      )}
                    />
                  </div>
                )}
              </>
            )}
          {userInfo?.grupos?.find(
            (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
          ) &&
            !currentCliente && (
              <>
                <Alert
                  type="warning"
                  message="Selecione um cliente para visualizar!"
                  showIcon
                />
                <br />
              </>
            )}
          <h3 style={{ marginBottom: "1rem" }}>Relatórios existentes</h3>
          <Table
            size="small"
            pagination={{ pageSize: 3 }}
            dataSource={anexosOwasp}
            columns={[
              {
                title: "Item",
                dataIndex: "_id",
                key: "_id",
                render: (text: string, record: any, index: number) =>
                  record.bucket.includes("csa-analises")
                    ? index + 1 + " - CSA"
                    : index + 1 + " - OWASP",
              },
              {
                title: "Data geração",
                dataIndex: "name",
                key: "name",
                render: (text: string, record: any) =>
                  moment(
                    new Date(
                      text.includes("-ZAP-Report")
                        ? text.replace("-ZAP-Report-.json", "")
                        : record.created_at
                    )
                  ).format("DD/MM/YYYY"),
              },
              {
                title: "Ações",
                dataIndex: "_id",
                key: "_id",
                render: (text: string, record: any) => (
                  <Button
                    type="link"
                    onClick={() => {
                      if (record.name.includes("-ZAP-Report")) {
                        downloadAndReadFile(record.url);
                      } else {
                        moduloCsa
                          .getResultadosCsa(record._id)
                          .then((data: any) => {
                            setResultadosCsa(data);
                          })
                          .finally(() => setCurrentRelatorio(null));
                      }
                    }}
                    icon={<EyeOutlined />}
                  >
                    Ver
                  </Button>
                ),
              },
            ]}
          />
        </>
      )}

      {/* Cabeçalho se uma análise for selecionada */}
      {(currentRelatorio || resultadosCsa) && (
        <BackHeader
          title="Resultados Pentest"
          prevAction={() => {
            setResultadosCsa(null);
            setCurrentRelatorio(null);
          }}
        />
      )}
      {/* OWASP */}
      {currentRelatorio && (
        <>
          <h3 style={{ marginBottom: "1rem" }}>Visualização</h3>
          <div>
            {currentRelatorio["@programName"] && (
              <>
                <Table
                  pagination={false}
                  dataSource={[
                    {
                      "@programName": currentRelatorio["@programName"],
                      "@version": currentRelatorio["@version"],
                      "@generated": currentRelatorio["@generated"],
                    },
                  ]}
                  columns={columns}
                />

                <br />
                <Collapse
                  items={currentRelatorio.site.map(
                    (site: any, index: number) => {
                      return {
                        key: index + 1,
                        label: `${site["@name"]} :${site["@port"]}`,
                        extra: site.alerts[0] ? (
                          <ExclamationCircleOutlined
                            style={{ color: "#fc0303" }}
                          />
                        ) : (
                          <CheckCircleOutlined style={{ color: "#22c900" }} />
                        ),
                        children: site.alerts[0] ? (
                          site.alerts.map((alerta: any, index2: number) => (
                            <Collapse
                              items={[
                                {
                                  key: index2 + 1 + alerta.name,
                                  label: `${alerta.name}`,
                                  extra: (
                                    <p
                                      style={{
                                        color: alerta.riskdesc.includes(
                                          "Informativo"
                                        )
                                          ? "#0054d1"
                                          : alerta.riskdesc.includes("Baixo (")
                                          ? "#ffb638"
                                          : alerta.riskdesc.includes("Médio (")
                                          ? "#ff6a00"
                                          : "#fc0303",
                                      }}
                                    >
                                      {alerta.riskdesc}
                                    </p>
                                  ),
                                  children: (
                                    <>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: alerta.desc,
                                        }}
                                      />
                                      <br />
                                      <h4>Recomendação</h4>
                                      <div
                                        style={{ marginLeft: "1rem" }}
                                        dangerouslySetInnerHTML={{
                                          __html: alerta.solution,
                                        }}
                                      />
                                      <br />
                                      <Collapse
                                        items={[
                                          {
                                            key: index2 + alerta.desc,
                                            label: `Instâncias (${alerta.instances.length})`,
                                            children: (
                                              <Collapse
                                                items={alerta.instances.map(
                                                  (
                                                    instance: any,
                                                    index3: number
                                                  ) => {
                                                    return {
                                                      key:
                                                        index3 + instance.uri,
                                                      label: (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <Tag
                                                            color={
                                                              instance.method ===
                                                              "GET"
                                                                ? "green"
                                                                : instance.method ===
                                                                  "POST"
                                                                ? "#0054d1"
                                                                : ""
                                                            }
                                                          >
                                                            GET
                                                          </Tag>
                                                          <p>{instance.uri}</p>
                                                        </div>
                                                      ),
                                                      children: (
                                                        <>
                                                          <h4>Evidencia:</h4>
                                                          <p
                                                            style={{
                                                              marginLeft:
                                                                "1rem",
                                                            }}
                                                          >
                                                            {instance.evidence}
                                                          </p>
                                                          <br />
                                                          <h4>
                                                            Outras Informações
                                                          </h4>
                                                          <div
                                                            style={{
                                                              marginLeft:
                                                                "1rem",
                                                            }}
                                                            dangerouslySetInnerHTML={{
                                                              __html:
                                                                instance.otherinfo,
                                                            }}
                                                          />
                                                        </>
                                                      ),
                                                    };
                                                  }
                                                )}
                                              />
                                            ),
                                          },
                                        ]}
                                      />
                                    </>
                                  ),
                                },
                              ]}
                            />
                          ))
                        ) : (
                          <Empty description="Nenhuma falha de segurança detectada" />
                        ),
                      };
                    }
                  )}
                />
              </>
            )}
          </div>
        </>
      )}

      {/* NUCLEI */}
      {resultadosCsa?.length > 0 && (
        <>
          <h4 style={{ marginBottom: "1rem" }}>Informações da análise</h4>
          <Table
            size="small"
            pagination={false}
            dataSource={[currentCliente]}
            className="tbl-header-roxo"
            columns={[
              {
                title: "Razão Social",
                dataIndex: "razao_social",
                key: "razao_social",
                render: (text: string) => text,
              },
              {
                title: "URLs",
                dataIndex: "urls",
                key: "urls",
                render: (urls: string[]) =>
                  urls?.[0] ? urls.map((url) => url).join(", ") : "-",
              },
              {
                title: "IPs",
                dataIndex: "ips",
                key: "ips",
                render: (ips: string[]) =>
                  ips?.[0] ? ips.map((ip) => ip).join(", ") : "-",
              },
            ]}
          />
          <br />
          {/* Blocos dashboard */}
          <Row gutter={24}>
            <Col span={8}>
              <DashboardItem
                text={quantidadeIps || ""}
                title="IPs análisados"
              />
              <DashboardItem
                title="Portas abertas"
                text={quantidadePortas || ""}
              />
            </Col>
            <Col span={8}>
              <DashboardItem
                title="URLs análisadas"
                text={currentCliente?.urls?.length || "0"}
              />
              <DashboardItem
                title="Última atualização"
                text={
                  moment(
                    new Date(
                      resultadosCsa?.[resultadosCsa.length - 1]?.created_at
                    )
                  ).format("DD/MM/YYYY HH:mm") || ""
                }
                textSize={30}
              />
            </Col>
            <Col span={8}>
              <div className="dashboard_item--double">
                <h4>Resultados / Grau de risco</h4>
                {itensPorSeveridade && (
                  <PieChart width={400} height={340}>
                    <Pie
                      data={Object.entries(itensPorSeveridade).map(
                        ([key, value]) => ({
                          label: key.toUpperCase(),
                          value,
                        })
                      )}
                      dataKey="value"
                      cx={200}
                      cy={150} // Ajuste para deixar espaço abaixo para a legenda
                      innerRadius={55}
                      outerRadius={90}
                      strokeWidth={0}
                      // label={({ label, value }) => `${label}: ${value}`}
                      // label={({ label, value }) => `${value}`}
                    >
                      {Object.entries(itensPorSeveridade).map(
                        ([key], index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={colors[key as keyof SeverityCounts]}
                            style={{
                              filter: `drop-shadow(10px 10px 25px #00000040)`,
                            }}
                          />
                        )
                      )}
                    </Pie>
                    <Legend
                      layout="horizontal"
                      align="center"
                      verticalAlign="bottom"
                      wrapperStyle={{
                        marginTop: "20px",
                      }}
                      payload={Object.entries(itensPorSeveridade).map(
                        ([key, value]) => ({
                          value: `${value} ${
                            severityLabels[key as keyof SeverityCounts]
                          }`,
                          type: "circle",
                          color: colors[key as keyof SeverityCounts],
                        })
                      )}
                    />
                  </PieChart>
                )}
              </div>
            </Col>
          </Row>
          {/* Lista de vulnerabilidades */}
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: <span style={{ color: "red" }}>ALTOS</span>,
                children: (
                  <Collapse
                    items={resultadosCsa
                      ?.filter((item: any) =>
                        ["high", "critical", "alto", "severe"].includes(
                          item?.severity?.toLowerCase()
                        )
                      )
                      ?.map((item: any, index: number) => {
                        return {
                          key: index + 1,
                          label: `${item.host}:${item.port}`,
                          children: (
                            <>
                              <h4>Resumo:</h4>
                              <p>{item.resume}</p>
                              <br />
                              {item.suggestionsForCorrection && (
                                <>
                                  <h4>Sugestões de Correção:</h4>
                                  <p>{item.suggestionsForCorrection}</p>
                                  <br />
                                </>
                              )}
                              {item.bestPractices && (
                                <>
                                  <h4>Boas Práticas:</h4>
                                  <p>{item.bestPractices}</p>
                                  <br />
                                </>
                              )}
                              {item.reference && (
                                <h4>
                                  <h4>Referências:</h4>
                                  {item.reference
                                    ?.split(",")
                                    ?.map((link: string, index: number) => (
                                      <span style={{ fontSize: 14 }}>
                                        <a
                                          href={link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            fontSize: 14,
                                            color: "#5f29cc",
                                          }}
                                        >
                                          {link}
                                        </a>
                                        {item.reference?.split(",")?.length -
                                          1 !==
                                          index && ", "}
                                      </span>
                                    ))}
                                  <br />
                                </h4>
                              )}
                              <h4>Tags:</h4>
                              <p>{item.tags}</p>
                              <br />
                              {item.cve && item.cve !== "null" && (
                                <>
                                  <h4>CVE:</h4>
                                  <p>{item.cve}</p>
                                  <br />
                                </>
                              )}
                              {item.cwe && (
                                <>
                                  <h4>CWE:</h4>
                                  <p>{item.cwe}</p>
                                  <br />
                                </>
                              )}
                              {item.cvssMetrics && (
                                <>
                                  <h4>Métricas CVSS:</h4>
                                  <p>{item.cvssMetrics}</p>
                                  <br />
                                </>
                              )}
                              {item.cvssScore && (
                                <>
                                  <h4>Score CVSS:</h4>
                                  <p>{item.cvssScore}</p>
                                  <br />
                                </>
                              )}
                            </>
                          ),
                        };
                      })}
                  />
                ),
              },
              {
                key: "2",
                label: <span style={{ color: "orange" }}>MÉDIOS</span>,
                children: (
                  <Collapse
                    items={resultadosCsa
                      ?.filter((item: any) =>
                        ["medium", "médio", "média"].includes(
                          item?.severity?.toLowerCase()
                        )
                      )
                      ?.map((item: any, index: number) => {
                        return {
                          key: index + 1,
                          label: `${item.host}:${item.port}`,
                          children: (
                            <>
                              <h4>Resumo:</h4>
                              <p>{item.resume}</p>
                              <br />
                              {item.suggestionsForCorrection && (
                                <>
                                  <h4>Sugestões de Correção:</h4>
                                  <p>{item.suggestionsForCorrection}</p>
                                  <br />
                                </>
                              )}
                              {item.bestPractices && (
                                <>
                                  <h4>Boas Práticas:</h4>
                                  <p>{item.bestPractices}</p>
                                  <br />
                                </>
                              )}
                              {item.reference && (
                                <>
                                  <h4>Referências:</h4>
                                  {item.reference
                                    ?.split(",")
                                    ?.map((link: string, index: number) => (
                                      <div style={{ fontSize: 14 }}>
                                        <a
                                          href={link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            fontSize: 14,
                                            color: "#5f29cc",
                                          }}
                                        >
                                          {link}
                                        </a>
                                        {item.reference?.split(",")?.length -
                                          1 !==
                                          index && ", "}
                                      </div>
                                    ))}
                                  <br />
                                </>
                              )}
                              <h4>Tags:</h4>
                              <p>{item.tags}</p>
                              <br />
                              {item.cve && item.cve !== "null" && (
                                <>
                                  <h4>CVE:</h4>
                                  <p>{item.cve}</p>
                                  <br />
                                </>
                              )}
                              {item.cwe && (
                                <>
                                  <h4>CWE:</h4>
                                  <p>{item.cwe}</p>
                                  <br />
                                </>
                              )}
                              {item.cvssMetrics && (
                                <>
                                  <h4>Métricas CVSS:</h4>
                                  <p>{item.cvssMetrics}</p>
                                  <br />
                                </>
                              )}
                              {item.cvssScore && (
                                <>
                                  <h4>Score CVSS:</h4>
                                  <p>{item.cvssScore}</p>
                                  <br />
                                </>
                              )}
                            </>
                          ),
                        };
                      })}
                  />
                ),
              },
              {
                key: "3",
                label: <span style={{ color: "green" }}>BAIXOS</span>,
                children: (
                  <Collapse
                    items={resultadosCsa
                      ?.filter((item: any) =>
                        ["low", "baixa", "minor"].includes(
                          item?.severity?.toLowerCase()
                        )
                      )
                      ?.map((item: any, index: number) => {
                        return {
                          key: index + 1,
                          label: `${item.host}:${item.port}`,
                          children: (
                            <>
                              <h4>Resumo:</h4>
                              <p>{item.resume}</p>
                              <br />
                              {item.suggestionsForCorrection && (
                                <>
                                  <h4>Sugestões de Correção:</h4>
                                  <p>{item.suggestionsForCorrection}</p>
                                  <br />
                                </>
                              )}
                              {item.bestPractices && (
                                <>
                                  <h4>Boas Práticas:</h4>
                                  <p>{item.bestPractices}</p>
                                  <br />
                                </>
                              )}
                              {item.reference && (
                                <h4>
                                  <h4>Referências:</h4>
                                  {item.reference
                                    ?.split(",")
                                    ?.map((link: string, index: number) => (
                                      <span style={{ fontSize: 14 }}>
                                        <a
                                          href={link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{
                                            fontSize: 14,
                                            color: "#5f29cc",
                                          }}
                                        >
                                          {link}
                                        </a>
                                        {item.reference?.split(",")?.length -
                                          1 !==
                                          index && ", "}
                                      </span>
                                    ))}
                                  <br />
                                </h4>
                              )}
                              <h4>Tags:</h4>
                              <p>{item.tags}</p>
                              <br />
                              {item.cve && item.cve !== "null" && (
                                <>
                                  <h4>CVE:</h4>
                                  <p>{item.cve}</p>
                                  <br />
                                </>
                              )}
                              {item.cwe && (
                                <>
                                  <h4>CWE:</h4>
                                  <p>{item.cwe}</p>
                                  <br />
                                </>
                              )}
                              {item.cvssMetrics && (
                                <>
                                  <h4>Métricas CVSS:</h4>
                                  <p>{item.cvssMetrics}</p>
                                  <br />
                                </>
                              )}
                              {item.cvssScore && (
                                <>
                                  <h4>Score CVSS:</h4>
                                  <p>{item.cvssScore}</p>
                                  <br />
                                </>
                              )}
                            </>
                          ),
                        };
                      })}
                  />
                ),
              },
              {
                key: "4",
                label: <span style={{ color: "#5f29cc" }}>INFORMAÇÕES</span>,
                children: (
                  <Collapse
                    items={resultadosCsa
                      ?.filter((item: any) =>
                        ["info", "informação"].includes(
                          item?.severity?.toLowerCase()
                        )
                      )
                      ?.map((item: any, index: number) => {
                        return {
                          key: index + 1,
                          label: `${item.host}:${item.port}`,
                          children: (
                            <>
                              <h4>Resumo:</h4>
                              <p>{item.resume}</p>
                              <br />
                              {item.suggestionsForCorrection && (
                                <>
                                  <h4>Sugestões de Correção:</h4>
                                  <p>{item.suggestionsForCorrection}</p>
                                  <br />
                                </>
                              )}
                              {item.bestPractices && (
                                <>
                                  <h4>Boas Práticas:</h4>
                                  <p>{item.bestPractices}</p>
                                  <br />
                                </>
                              )}
                              {item.reference && (
                                <>
                                  <h4>
                                    <h4>Referências:</h4>
                                    {item.reference
                                      ?.split(",")
                                      ?.map((link: string, index: number) => (
                                        <span style={{ fontSize: 14 }}>
                                          <a
                                            href={link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                              fontSize: 14,
                                              color: "#5f29cc",
                                            }}
                                          >
                                            {link}
                                          </a>
                                          {item.reference?.split(",")?.length -
                                            1 !==
                                            index && ", "}
                                        </span>
                                      ))}
                                  </h4>
                                  <br />
                                </>
                              )}
                              <h4>Tags:</h4>
                              <p>{item.tags}</p>
                              <br />
                              {item.cve && item.cve !== "null" && (
                                <>
                                  <h4>CVE:</h4>
                                  <p>{item.cve}</p>
                                  <br />
                                </>
                              )}
                              {item.cwe && (
                                <>
                                  <h4>CWE:</h4>
                                  <p>{item.cwe}</p>
                                  <br />
                                </>
                              )}
                              {item.cvssMetrics && (
                                <>
                                  <h4>Métricas CVSS:</h4>
                                  <p>{item.cvssMetrics}</p>
                                  <br />
                                </>
                              )}
                              {item.cvssScore && (
                                <>
                                  <h4>Score CVSS:</h4>
                                  <p>{item.cvssScore}</p>
                                  <br />
                                </>
                              )}
                            </>
                          ),
                        };
                      })}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default OWASP;
