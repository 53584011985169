import React, { useState } from "react";
import "./styles.css";
import { Alert, Button, Table, Tag, Upload } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import {
  ArrowLeftOutlined,
  CalendarOutlined,
  FileExcelFilled,
} from "@ant-design/icons";
import Task from "../../../../components/Tarefas/Task/Task";
import * as XLSX from "xlsx";
import ModalImportarCve from "../../Questionarios/ListQuestionarios/components/ModalImportarCve/ModalImportarCve";
import moment from "moment";

const TarefasCliente = () => {
  const { moduloTask, currentCliente } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [categoriaId, setCategoriaId] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [importFile, setImportFile] = React.useState<any>(null);
  const [showImport, setShowImport] = React.useState<boolean>(false);
  const handleShowModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };

  React.useEffect(() => {
    if (currentCliente) {
      moduloTask?.fetchClienteTasks(currentCliente._id);
    }
  }, [currentCliente]);

  const handleImport = (file: any) => {
    setShowImport(true);

    const reader = new FileReader();

    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        const workbook: any = XLSX.read(e.target.result, { type: "buffer" });
        const worksheetName = "RELATÓRIO CSA";
        const worksheet = workbook.Sheets[worksheetName];
        const data: any = XLSX.utils.sheet_to_json(worksheet);
        let headerRow: any = data[1]; // Assume que a segunda linha contém os nomes das colunas

        // Verifica se o primeiro item do headerRow possui o formato "__EMPTY_" e ajusta se necessário
        if (!headerRow["__EMPTY"]) {
          headerRow = Object.keys(headerRow).reduce((acc: any, key: string) => {
            acc[`__EMPTY_${key}`] = headerRow[key];
            return acc;
          }, {});
        }

        const newData = data.slice(2).map((item: any) => {
          const newItem: any = {};
          for (const key in headerRow) {
            if (Object.prototype.hasOwnProperty.call(headerRow, key)) {
              const columnName = headerRow[key].replace(" ", "_").toUpperCase();
              newItem[columnName] = item[key] || item[`__EMPTY_${key}`];
            }
          }
          return newItem;
        });
        // newData[0]["HORA DE INÍCIO"] && setImportCve(true);
        setImportFile({ nome: data[0]["__EMPTY_6"], data: newData });
      };
    }

    return false;
  };

  return (
    <div className="templates-tarefas">
      <BackHeader
        prev
        title={`Tarefas${
          currentCliente?.razao_social
            ? " - " +
              (currentCliente?.razao_social?.length > 25
                ? currentCliente?.razao_social?.substring(0, 25) + "..."
                : currentCliente?.razao_social)
            : ""
        }
          `}
      />
      {currentCliente ? (
        <>
          <Upload
            showUploadList={false}
            beforeUpload={(file: any) => handleImport(file)}
            accept="text/csv,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          >
            <Button
              type="primary"
              style={{
                background: "green",
                marginBottom: "1rem",
                marginLeft: "-1.8rem",
              }}
              icon={<FileExcelFilled style={{ color: "#fff" }} />}
            >
              Importar CVE
            </Button>
          </Upload>

          {categoriaId ? (
            <>
              <Button
                icon={<ArrowLeftOutlined />}
                type="primary"
                style={{ marginBottom: "1rem" }}
                onClick={() => setCategoriaId(null)}
              >
                Voltar
              </Button>
              <Table
                size="small"
                dataSource={moduloTask.tasksCliente.filter(
                  (task) => task.modelo_referencia_id === categoriaId
                )}
                columns={[
                  {
                    title: "Tarefa",
                    dataIndex: "title",
                    key: "title",
                    render: (text, record: any) => (
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          moduloTask.setSelectedTask(record);
                          handleShowModal();
                        }}
                      >
                        {text}
                      </p>
                    ),
                  },
                ]}
              />
            </>
          ) : (
            <>
              <h3>Tarefas</h3>
              <br />
              <Table
                size="small"
                dataSource={moduloTask.tasksCliente
                  ?.filter((task) => !task.modelo_referencia_id)
                  ?.filter((task) => task.tipo_dependencia !== "checklist")}
                columns={[
                  {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (status, record: any) => (
                      <p
                        onClick={() => {
                          moduloTask.setSelectedTask(record);
                          handleShowModal();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <Tag
                          color={
                            status === "Em andamento"
                              ? "blue"
                              : status === "Concluída"
                              ? "green"
                              : "grey"
                          }
                        >
                          {status}
                        </Tag>
                      </p>
                    ),
                  },
                  {
                    title: "Descrição",
                    dataIndex: "title",
                    key: "title",
                    render: (text, record: any) => (
                      <p
                        onClick={() => {
                          moduloTask.setSelectedTask(record);
                          handleShowModal();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {text}
                      </p>
                    ),
                  },
                  {
                    title: "Atribuído",
                    dataIndex: "assignment_name",
                    key: "assignment_name",
                    render: (assignment_name, record: any) => (
                      <p
                        onClick={() => {
                          moduloTask.setSelectedTask(record);
                          handleShowModal();
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {assignment_name || "-"}
                      </p>
                    ),
                  },
                  {
                    title: "Prazo",
                    dataIndex: "prazo_conclusao",
                    key: "prazo_conclusao",
                    render: (prazo_conclusao, record: any) => (
                      <p
                        onClick={() => {
                          moduloTask.setSelectedTask(record);
                          handleShowModal();
                        }}
                        style={{ color: "#5f29cc", cursor: "pointer" }}
                      >
                        <b>
                          <CalendarOutlined />{" "}
                          {prazo_conclusao
                            ? moment(prazo_conclusao).format("DD/MM/YYYY")
                            : "-"}
                        </b>
                      </p>
                    ),
                  },
                ]}
              />
              <h3>Tarefas agrupadas</h3>
              <br />

              <Table
                size="small"
                dataSource={moduloTask.tasksCliente
                  .filter(
                    (task) =>
                      task.modelo_info &&
                      task.modelo_info.cliente_id === currentCliente._id
                  )
                  .map((task) => task.modelo_info)
                  .reduce((acc, task) => {
                    const stringifiedTask = JSON.stringify(task);
                    if (!acc.includes(stringifiedTask)) {
                      acc.push(stringifiedTask);
                    }
                    return acc;
                  }, [])
                  .map((task: any) => JSON.parse(task))}
                columns={[
                  {
                    title: "Descrição",
                    dataIndex: "nome",
                    key: "nome",
                    render: (text, record: any) => (
                      <p
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCategoriaId(record._id);
                        }}
                      >
                        {text}
                      </p>
                    ),
                  },
                ]}
              />
            </>
          )}
          <Task setShowModal={setShowModal} showModal={showModal} />
          <ModalImportarCve
            clientId={currentCliente._id}
            importFile={importFile}
            setImportFile={setImportFile}
            setShowImport={setShowImport}
            showImport={showImport}
          />
        </>
      ) : (
        <Alert
          type="warning"
          message="Selecione um cliente para visualizar!"
          showIcon
        />
      )}
    </div>
  );
};

export default TarefasCliente;
