//import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import "./styles.css";
import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";
import { IndiceTypes } from "../../../@types/indice";
import { Collapse } from "antd";

const Indice: React.FC<IndiceTypes> = ({
  questionario,
  activeCat,
  setActiveCat,
}) => {
  const { moduloProcessos, windowWidth } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  useEffect(() => {
    if (
      moduloProcessos.areas &&
      !activeCat &&
      questionario &&
      questionario.capitulos
    ) {
      const primeiraAreaId = moduloProcessos.areas?.filter((area: any) =>
        questionario.areas.includes(area._id)
      )[0]._id;
      setActiveCat(
        questionario.capitulos?.filter((cap: any) =>
          cap?.areas_id?.includes(primeiraAreaId)
        )?.[0]?._id
      );
    }
  }, [moduloProcessos.areas, questionario, activeCat]);

  const itemsCollapse = [
    {
      key: "1",
      label: <b style={{ color: "#5f29cc" }}>Índice</b>,
      children: (
        <div className="analise__indice--itens">
          {questionario &&
            questionario !== null &&
            questionario.areas &&
            moduloProcessos.areas
              ?.filter((area: any) => questionario.areas?.includes(area._id))
              .map((area: any, indexArea: number) => {
                return (
                  <div className="indice__area">
                    {questionario?.areas?.length > 1 && (
                      <p>{area?.description}</p>
                    )}
                    {questionario.capitulos
                      .filter((cap: any) => cap?.areas_id?.includes(area._id))
                      .map((cap: any, index: number) => {
                        return (
                          <>
                            {questionario.perguntasRelacao.filter(
                              (p: any) => p.capitulo_id === cap._id
                            )[0] ? (
                              <p
                                style={{
                                  marginLeft:
                                    questionario.areas.lenght > 1
                                      ? "1rem"
                                      : "0",
                                }}
                                className={`${
                                  activeCat === cap._id
                                    ? "analise__indice--cap__active"
                                    : "analise__indice--cap"
                                }`}
                                onClick={() => setActiveCat(cap._id)}
                              >
                                {cap.description}
                              </p>
                            ) : null}
                          </>
                        );
                      })}
                  </div>
                );
              })}
        </div>
      ),
      showArrow: false,
    },
  ];
  return (
    <div className="analise__indice">
      <Collapse
        collapsible={"disabled"}
        items={itemsCollapse}
        defaultActiveKey={["1"]}
      />
    </div>
  );
};

export default Indice;
