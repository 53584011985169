import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import {
  BarChartOutlined,
  FileProtectOutlined,
  FileSearchOutlined,
  HomeOutlined,
  LockOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ScheduleOutlined,
  ShoppingCartOutlined,
  UnlockOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  MessageOutlined,
  ProfileOutlined,
  FileOutlined,
  PlusOutlined,
  EditOutlined,
  SafetyCertificateOutlined,
  VideoCameraOutlined,
  AuditOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Button, Divider, MenuProps, Select, Space, Tooltip } from "antd";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../context/globalContext";
import { GlobalContextType } from "../../@types/global";
import { DocumentText, KeySquare, Map1, NoteText, Shield } from "iconsax-react";
import LogoPam from "../../assets/images/logo_white_background.png";
import NewCliente from "../NewCliente/NewCliente";

type MenuItem = Required<MenuProps>["items"][number];

const rootSubmenuKeys = ["clientesFornecedor"];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group" | "",
  disabled?: boolean
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  } as MenuItem;
}

const Drawer: React.FC = () => {
  const {
    setCurrentRoute,
    openKeys,
    setOpenKeys,
    moduloMapeamento,
    collapsed,
    setCurrentCliente,
    currentCliente,
    userInfo,
    modulosPermissao,
    clientes,
    setCollapsed,
    getModulosPermissao,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const navigate = useNavigate();
  const location = useLocation();

  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    clientes && clientes.length >= 1 && getModulosPermissao(clientes[0]._id);
  }, [clientes]);

  const items: MenuItem[] = [
    // Somente Seusdados ver usuarios completo
    ...(userInfo?.grupos?.find((gp: any) => gp.name === "seusdados")
      ? [
          getItem("Gestão de atividades", "tarefas", <ScheduleOutlined />),
          getItem("Usuários", "usuarios", <UserOutlined />),
          ...(currentCliente
            ? []
            : [
                getItem(
                  "Mapeamentos",
                  "map",
                  <Map1 style={{ width: 14, height: 14 }} />,
                  [
                    ...(userInfo?.cliente_id === "64e648ff902fd5e820397ebb"
                      ? [
                          getItem(
                            "Gerenciar Áreas / Processos / Perguntas",
                            "capitulos"
                          ),
                          getItem(
                            "Gerar modelo",
                            "templates",
                            <FileProtectOutlined />
                          ),
                        ]
                      : []),
                  ],
                  "",
                  userInfo?.cliente_id === "64e648ff902fd5e820397ebb"
                    ? false
                    : true
                ),
                getItem("Governança", "gov", <BarChartOutlined />, [
                  getItem(
                    "CPPD",
                    `cppdgrupo`,
                    <ProfileOutlined />,
                    [getItem("Modelos de pautas", `pautas`)],
                    "",
                    userInfo?.cliente_id === "64e648ff902fd5e820397ebb"
                      ? false
                      : true
                  ),
                ]),
              ]),
        ]
      : []),
    ...(currentCliente
      ? [
          ...(userInfo?.grupos?.find(
            (gp: any) =>
              gp.name !== "seusdados" &&
              userInfo?.cliente_id === "64e648ff902fd5e820397ebb"
          )
            ? [getItem("Usuários", "usuarios", <UserOutlined />)]
            : []),
          ...(userInfo?.cliente_id === "64e648ff902fd5e820397ebb"
            ? [
                getItem(
                  "Programa de Privacidade",
                  `programaprivacidade`,
                  <SafetyCertificateOutlined />
                ),
              ]
            : []),
          ...(userInfo?.cliente_id === "64e648ff902fd5e820397ebb"
            ? [
                getItem(
                  "Liberação de Módulos",
                  "liberacao",
                  <KeySquare style={{ width: 14, height: 14 }} />
                ),
              ]
            : [
                getItem(
                  "Liberação de Módulos",
                  "liberacao",
                  <KeySquare style={{ width: 14, height: 14 }} />,
                  [],
                  "",
                  true
                ),
              ]),
          getItem("Governança", "gov", <BarChartOutlined />, [
            getItem(
              "Maturidade de Governança",
              "questionarioscppd",
              <NoteText style={{ width: 16, height: 16 }} />
            ),
            getItem(
              "CPPD",
              `cppdgrupo`,
              <ProfileOutlined />,
              [
                getItem(
                  "Instauração do comitê",
                  `cppd`,
                  <VideoCameraOutlined />
                ),
                ...(userInfo?.cliente_id === "64e648ff902fd5e820397ebb"
                  ? [getItem("Modelos de pautas", `pautas`)]
                  : []),
              ],
              "",
              userInfo?.cliente_id === "64e648ff902fd5e820397ebb" ? false : true
            ),
            getItem("RIPD", "questionariosripd", <FileProtectOutlined />),
            getItem("LIA", "questionarioslia", <AuditOutlined />),
            getItem("ROT (ROPA)", "fichas", <FileSearchOutlined />),
            getItem(
              "Fornecedores e Parceiros",
              "acaofornecedores",
              <ShoppingCartOutlined />,
              [
                getItem("Análise consolidada", "dashboardContratante"),
                getItem(
                  "Parceiros / Fornecedores",
                  `clientesFornecedor/fornecedor/${currentCliente._id}`
                ),
              ]
            ),
          ]),
          getItem(
            "Mapeamentos",
            "map",
            <Map1 style={{ width: 14, height: 14 }} />,
            [
              getItem("Ficha de processo", "fichas", <FileSearchOutlined />),
              ...(userInfo?.cliente_id === "64e648ff902fd5e820397ebb"
                ? [
                    getItem(
                      "Gerenciar Áreas / Processos / Perguntas",
                      "capitulos"
                    ),
                    getItem(
                      "Gerar modelo",
                      "templates",
                      <FileProtectOutlined />
                    ),
                  ]
                : []),
              getItem(
                "Aplicar mapeamento",
                "aplicacoes1",
                <FileOutlined />,
                [],
                "",
                true
              ),
              getItem("Mapeamentos realizados", "aplicacoes", <FileOutlined />),
              getItem(
                "Mapeamentos Extras",
                "aplicacoes3",
                <FileOutlined />,
                [],
                "",
                true
              ),
            ],
            "",
            userInfo?.cliente_id === "64e648ff902fd5e820397ebb" ? false : true
          ),
          getItem(
            "Seusdados Cybersecurity",
            "csa",
            <Shield style={{ width: 14, height: 14 }} />,
            [
              getItem(
                "Resultados Pentest",
                "csaresultados",
                <ThunderboltOutlined />
              ),
            ],
            "",
            userInfo?.cliente_id === "64e648ff902fd5e820397ebb" ? false : true
          ),
        ]
      : []),

    getItem(
      "Comercial",
      "comercial",
      <ScheduleOutlined />,
      [getItem("Diagnósticos", "diagnosticos"), getItem("Leads", "leads")],
      "",
      userInfo?.cliente_id === "64e648ff902fd5e820397ebb" ? false : true
    ),

    // getItem("Tarefas", "tarefascli", <ScheduleOutlined />, [
    //   getItem("Tarefas por clientes", "templates"),
    //   getItem("Modelos", "modelostarefas"),
    // ]),
  ];

  const itensNotAssinante: MenuItem[] = [
    getItem("Visão geral", "dashboardinicial", <HomeOutlined />),
    // getItem("Minhas tarefas", "tarefas", <FileDoneOutlined />),
    ...(userInfo?.grupos?.find(
      (gp: any) =>
        gp.name === "sponsor" ||
        gp.name === "Gestor de área" ||
        gp.name === "Líder de processo"
    )
      ? [getItem("Fichas de processo", "fichas", <FileSearchOutlined />)]
      : []),
    ...(moduloMapeamento.questionarios.find(
      (item: any) => item.nome === "Assessment Process"
    )
      ? [
          getItem(
            "Mapeamento",
            `mapeamentos`,
            <Map1 style={{ width: 14, height: 14 }} />
          ),
        ]
      : []),
    ...moduloMapeamento.questionarios
      ?.filter((item: any) => item.nome !== "Assessment Process")
      ?.sort(
        (a: any, b: any) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      )
      ?.filter(() => userInfo?.grupos?.find((gp: any) => gp.name === "sponsor"))
      .map((quest: any) =>
        getItem(
          quest.nome,
          `clientes/editar/${quest.cliente_id}/ver/${quest._id}`
        )
      ),
    getItem("Análise de vulnerabilidades", `analise`, <UnlockOutlined />, [
      getItem("Resultados Pentest", "csaresultados", <ThunderboltOutlined />),
      getItem("Questionário de T.I.", "csaquest", null),
      getItem("Análise do site", "owasp", null),
    ]),
    ...(modulosPermissao?.find(
      (p: any) =>
        clientes?.find((c: any) => c._id === p.cliente_id) && !p.inativo
    )
      ? [
          getItem(
            "Nível de maturidade",
            "acaofornecedores",
            <BarChartOutlined />,
            [
              getItem("Análise consolidada", "dashboardContratante"),
              getItem("Parceiros / Fornecedores", "clientesFornecedor"),
            ]
          ),
        ]
      : []),
  ];

  const itensNotAssinanteFornecedor: MenuItem[] = [
    getItem("Visão geral", "dashboardinicial", <HomeOutlined />),
    // getItem("Minhas tarefas", "tarefas", <FileDoneOutlined />),
    ...(userInfo?.grupos?.find(
      (gp: any) =>
        gp.name === "sponsor" ||
        gp.name === "Gestor de área" ||
        gp.name === "Líder de processo"
    )
      ? [
          getItem("Fichas de processo", "fichas", <FileSearchOutlined />),
          ...(moduloMapeamento.questionarios.find(
            (item: any) => item.nome === "Assessment Process"
          )
            ? [
                getItem(
                  "Mapeamento",
                  `mapeamentos`,
                  <Map1 style={{ width: 14, height: 14 }} />
                ),
              ]
            : []),
        ]
      : []),
    ...(userInfo?.grupos?.find((gp: any) => gp.name === "sponsor")
      ? [
          ...moduloMapeamento.questionarios
            .filter(
              (q: any) =>
                q.nome !== "Questionário Tecnologia da Informação - CSA"
            )
            ?.filter((item: any) => item.nome !== "Assessment Process")
            ?.sort(
              (a: any, b: any) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .map((quest: any) =>
              getItem(
                quest.nome,
                `clientes/editar/${quest.cliente_id}/ver/${quest._id}`
              )
            ),
          getItem(
            "Análise de vulnerabilidades",
            `analise`,
            <UnlockOutlined />,
            [
              getItem(
                "Resultados Pentest",
                "csaresultados",
                <ThunderboltOutlined />
              ),
              getItem("Questionário de T.I.", "csaquest", null),
              getItem("Análise do site", "owasp", null),
            ]
          ),
        ]
      : []),
    getItem("Nível de maturidade", "acaofornecedores", <BarChartOutlined />, [
      getItem("Análise consolidada", "dashboardContratante"),
      getItem("Parceiros / Fornecedores", "clientesFornecedor"),
    ]),
  ];

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onSelect: MenuProps["onSelect"] = (e) => {
    navigate(e.key, { replace: true });
    setCurrentRoute(e.keyPath[1]);
  };

  return (
    <>
      <div className={`drawer ${collapsed && "drawer-collapsed"}`}>
        <img
          onClick={() => navigate("/dashboardinicial")}
          src={LogoPam}
          style={{ cursor: "pointer" }}
          alt="SeusDados"
          className="logo"
        />

        {collapsed ? (
          <MenuUnfoldOutlined
            className="collapseIcon"
            onClick={toggleCollapsed}
          />
        ) : (
          <MenuFoldOutlined
            className="collapseIcon"
            onClick={toggleCollapsed}
          />
        )}

        {userInfo?.grupos?.find(
          (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
        ) && (
          <div style={{ marginTop: "3rem", marginBottom: "-1.45rem" }}>
            <UsergroupAddOutlined
              style={{
                color: "#fff",
                marginRight: "0.7rem",
                marginLeft: "0.2rem",
                fontSize: 14,
              }}
            />
            <Select
              size="small"
              showSearch
              style={{ width: currentCliente?._id ? "183.5px" : "13rem" }}
              placeholder="Selecione um cliente"
              optionFilterProp="filter"
              filterOption={(input: any, option: any) =>
                option?.filter.toLowerCase().includes(input.toLowerCase())
              }
              options={clientes
                ?.filter(
                  (c: any) =>
                    c.cliente_sup || c._id === "64e648ff902fd5e820397ebb"
                )
                ?.filter((c: any) =>
                  userInfo?.grupos?.find((gp: any) => gp.name === "seusdados")
                    ? c.cliente_sup === "64e648ff902fd5e820397ebb" ||
                      c._id === "64e648ff902fd5e820397ebb" // Seusdados
                    : true
                )
                .map((cli: any) => {
                  if (cli._id === "64e648ff902fd5e820397ebb") {
                    return {
                      value: cli._id,
                      filter: cli.razao_social,
                      label: (
                        <span style={{ color: "#5f29cc", fontWeight: "bold" }}>
                          {cli.razao_social}
                        </span>
                      ),
                    };
                  }

                  return {
                    key: cli._id,
                    value: cli._id,
                    filter: cli.razao_social,
                    label: cli.razao_social,
                  };
                })
                ?.sort((a: any, b: any) => {
                  // Garantir que o item com razao_social === "Apresentação" seja o primeiro
                  if (a.label === "Apresentação") return -1;
                  if (b.label === "Apresentação") return 1;
                  return a.label.localeCompare(b.label);
                })}
              onChange={(c) => {
                setCurrentCliente(clientes.find((cli: any) => cli._id === c));

                if (location.pathname !== "/tarefas") {
                  navigate("/dashboardinicial");
                }
              }}
              value={currentCliente?._id}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      className="clientes__actions--new"
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => setShowModal(true)}
                      style={{
                        width: currentCliente?._id ? "10rem" : "11.5rem",
                      }}
                    >
                      Novo Cliente
                    </Button>
                  </Space>
                </>
              )}
            />
            {currentCliente?._id && (
              <Tooltip title="Editar dados do cliente selecionado">
                <EditOutlined
                  style={{
                    color: "#fff",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/clientes/editar")}
                />
              </Tooltip>
            )}
          </div>
        )}
        <Menu
          mode="inline"
          onOpenChange={onOpenChange}
          items={
            userInfo?.grupos?.find(
              (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
            )
              ? userInfo?.grupos?.find((gp: any) => gp.name === "seusdados")
                ? [
                    ...items,
                    getItem("Feedbacks", `feedbacks`, <MessageOutlined />),
                  ]
                : items
              : clientes && clientes.length === 1
              ? modulosPermissao?.find(
                  (p: any) =>
                    clientes?.find((c: any) => c._id === p.cliente_id) &&
                    !p.inativo
                )
                ? itensNotAssinanteFornecedor
                : itensNotAssinante
              : itensNotAssinante
          }
          inlineCollapsed={collapsed}
          onSelect={onSelect}
          openKeys={openKeys}
          // defaultSelectedKeys={[location.pathname.replace("/", "")]}
          selectedKeys={[location.pathname.replace("/", "")]}
        />
      </div>

      <div
        className={`${collapsed ? "drawer__darkbg--dnone" : "drawer__darkbg"}`}
        onClick={() => setCollapsed(true)}
      />

      <NewCliente setShowModal={setShowModal} showModal={showModal} />
    </>
  );
};
export default Drawer;
