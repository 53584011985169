import React, { useEffect, useState } from "react";
import {
  Timeline,
  Spin,
  Button,
  Tooltip,
  Space,
  DatePicker,
  Dropdown,
  theme,
  Divider,
} from "antd";
import moment from "moment";
import { GlobalContext } from "../../context/globalContext";
import { GlobalContextType } from "../../@types/global";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { DatePickerProps } from "antd/lib";
import Task from "../Tarefas/Task/Task";

interface Log {
  _id: string;
  title: string;
  description?: string;
  created_at: string;
  user_id: string;
  local?: {
    reference_model_name: string;
    reference_model_id: string[];
  }[];
}

interface TimelineGeralProps {
  mapeamentoId?: string;
  logs?: any[] | null;
  loadingLogs?: boolean;
  filter?: string | null;
  setLogs?: any;
}
const { useToken } = theme;

const TimelineGeral: React.FC<TimelineGeralProps> = ({
  mapeamentoId,
  logs,
  loadingLogs,
  setLogs,
  filter,
}) => {
  const { usuarios, postLog, fetchClienteLogs, currentCliente, moduloTask } =
    React.useContext(GlobalContext) as GlobalContextType;

  const [visibleLogs, setVisibleLogs] = useState(6);
  const [filteredLogs, setFilteredLogs] = useState<any[] | null>(null);
  const [verMais, setVerMais] = useState<string | null>(null);
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
    {}
  );

  const [loadingProrrogar, setLoadingProrrogar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any | null>(null);
  const [selectedRegistro, setSelectedRegistro] = useState<any | null>(null);
  const [isVerTarefaModalVisible, setIsVerTarefaModalVisible] =
    useState<boolean>(false);

  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const disabledDate = (current: any) => {
    return current && current <= moment().endOf("day");
  };

  const handleDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    setSelectedDate(date);
  };

  const handleDropdownChange = (logId: string, isOpen: boolean) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [logId]: isOpen,
    }));
    if (isOpen) setSelectedRegistro(logs?.find((log) => log._id === logId));
  };

  const handleSave = () => {
    setLoadingProrrogar(true);
    postLog(
      "Registro adicionado",
      `${selectedRegistro.description
        ?.replace(/\s*\(Prazo:.*?\)/, "")
        ?.replace(/\s*\(Novo Prazo:.*?\)/, "")}${
        selectedDate
          ? "(Novo Prazo: " +
            moment(new Date(selectedDate)).format("DD/MM/YYYY") +
            ")"
          : "(Concluído)"
      }`,
      currentCliente._id,
      selectedDate
    )
      .then(() => fetchClienteLogs(currentCliente._id).then((r) => setLogs(r)))
      .finally(() => {
        setLoadingProrrogar(false);
        setSelectedRegistro(null);
        setSelectedDate(null);
        setOpenDropdowns({});
      });
  };

  const handleLoadMore = () => {
    setVisibleLogs((prevVisibleLogs) => prevVisibleLogs + 10); // Carregar mais 10 logs
  };

  useEffect(() => {
    if (logs && mapeamentoId) {
      setFilteredLogs(
        logs
          .sort(
            (a: Log, b: Log) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "questionario"
            )
              ? log.local?.find(
                  (local) => local.reference_model_name === "questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "responder-questionario"
            )
              ? log.local?.find(
                  (local) =>
                    local.reference_model_name === "responder-questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
      );
    }

    if (logs && mapeamentoId) {
      setFilteredLogs(
        logs
          .sort(
            (a: Log, b: Log) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "questionario"
            )
              ? log.local?.find(
                  (local) => local.reference_model_name === "questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "responder-questionario"
            )
              ? log.local?.find(
                  (local) =>
                    local.reference_model_name === "responder-questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
      );
    } else if (logs && !mapeamentoId) {
      setFilteredLogs(
        logs
          ?.filter((log) =>
            filter
              ? log.title.includes(filter) || log.description.includes(filter)
              : true
          )
          ?.sort(
            (a: Log, b: Log) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
      );
    }
  }, [logs, mapeamentoId, filter]);

  const truncateDescription = (description: any, maxLength = 15) => {
    if (description?.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  useEffect(() => {
    if (
      filteredLogs?.find(
        (l) =>
          l.local?.find((log: any) => log.reference_model_name === "tasks")
            ?.reference_model_id?.[0]
      )
    ) {
      moduloTask.fetchClienteTasks(currentCliente._id);
    }
  }, [filteredLogs]);

  return (
    <div>
      {/* historico */}
      {loadingLogs && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Spin size="small" />
          <p style={{ marginLeft: "0.5rem", color: "#b3b3b3" }}>
            Verificando logs...
          </p>
        </div>
      )}
      {logs && (
        <div
          style={{
            marginTop: "2rem",
          }}
        >
          <Timeline style={{ marginLeft: "1rem" }}>
            {filteredLogs
              ?.slice(0, visibleLogs)
              ?.filter((log) => !log.description?.includes("(Concluído)"))
              ?.map((log: Log) => {
                const isDone = filteredLogs?.find((l) => {
                  if (
                    l.description.includes("(Concluído)") &&
                    l.description?.replace("(Concluído)", "") ===
                      log.description
                        ?.replace(/\s*\(Prazo:.*?\)/, "")
                        ?.replace(/\s*\(Novo Prazo:.*?\)/, "")
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                });

                const novoPrazo = filteredLogs?.find((l) => {
                  if (
                    l.description.includes("(Novo Prazo:") &&
                    l.description?.replace(/\s*\(Novo Prazo:.*?\)/, "") ===
                      log.description?.replace(/\s*\(Prazo:.*?\)/, "")
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                });

                return (
                  <Timeline.Item key={log._id}>
                    <div className="ficha__log">
                      <p className="ficha__log--date">
                        {moment(new Date(log.created_at)).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </p>
                      <p className="ficha__log--title">
                        {log.title}{" "}
                        {usuarios?.find(
                          (usr: any) => usr._id === log.user_id
                        ) && (
                          <span className="ficha__log--user">
                            por{" "}
                            <span>
                              {
                                usuarios?.find(
                                  (usr: any) => usr._id === log.user_id
                                ).name
                              }
                            </span>
                          </span>
                        )}
                      </p>
                    </div>
                    <p className="ficha__log--desc">
                      {log.local?.find(
                        (l) => l.reference_model_name === "tasks"
                      ) &&
                        (moduloTask?.tasksCliente?.find(
                          (t) =>
                            t._id ===
                            log.local?.find(
                              (l) => l.reference_model_name === "tasks"
                            )?.reference_model_id?.[0]
                        ) ? (
                          <span
                            style={{
                              color: "#5f29cc",
                              marginRight: "0.5rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              moduloTask?.setSelectedTask(
                                moduloTask?.tasksCliente?.find(
                                  (t) =>
                                    t._id ===
                                    log.local?.find(
                                      (l) => l.reference_model_name === "tasks"
                                    )?.reference_model_id?.[0]
                                )!
                              );
                              setIsVerTarefaModalVisible(true);
                            }}
                          >
                            <EyeOutlined style={{ color: "#5f29cc" }} /> Ver
                            tarefa
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "#5f29cc",
                              marginRight: "0.5rem",
                              cursor: "pointer",
                            }}
                          >
                            <Spin size="small" style={{ color: "#5f29cc" }} />{" "}
                            Carregando...
                          </span>
                        ))}

                      {(log.description?.match(/\(Prazo:.*?\)/) ||
                        log.description?.match(/\(Novo Prazo:.*?\)/)) && (
                        <Tooltip
                          title={isDone ? "Item concluído" : "Editar prazo"}
                        >
                          {!isDone && !novoPrazo && (
                            <Dropdown
                              dropdownRender={(menu) => (
                                <div style={{ ...contentStyle, padding: 10 }}>
                                  <Space direction="vertical">
                                    <p>Prorrogar</p>
                                    <Space direction="horizontal">
                                      <DatePicker
                                        onChange={handleDateChange}
                                        style={{ width: 150 }}
                                        disabledDate={disabledDate}
                                        value={selectedDate}
                                      />
                                      <Button
                                        type="primary"
                                        onClick={() => {
                                          setSelectedRegistro(log);
                                          handleSave();
                                        }}
                                        icon={<SaveOutlined />}
                                        loading={loadingProrrogar}
                                      >
                                        Salvar
                                      </Button>
                                    </Space>
                                    <p>Finalizar item</p>
                                    <Space direction="horizontal">
                                      <Button
                                        type="primary"
                                        style={{ backgroundColor: "green" }}
                                        onClick={() => {
                                          setSelectedRegistro(log);
                                          handleSave();
                                        }}
                                        icon={<CheckOutlined />}
                                        loading={loadingProrrogar}
                                      >
                                        Concluir
                                      </Button>
                                    </Space>
                                  </Space>
                                </div>
                              )}
                              trigger={["click"]}
                              open={openDropdowns[log._id] || false}
                              onOpenChange={(isOpen) =>
                                handleDropdownChange(log._id, isOpen)
                              }
                            >
                              <span
                                style={{
                                  color: "orange",
                                  cursor: "pointer",
                                  marginRight: "0.5rem",
                                }}
                              >
                                <EditOutlined />{" "}
                                {log.description?.match(/\(Prazo:.*?\)/)?.[0]}{" "}
                                {
                                  log.description?.match(
                                    /\(Novo Prazo:.*?\)/
                                  )?.[0]
                                }
                              </span>
                            </Dropdown>
                          )}

                          {/* se o item tiver prorrogação de prazo */}
                          {novoPrazo && (
                            <Tooltip title="Item prorrogado">
                              <span
                                style={{
                                  color: "orange",
                                  marginRight: "0.5rem",
                                }}
                              >
                                <CloseOutlined style={{ color: "orange" }} />{" "}
                                (Prorrogado)
                              </span>
                            </Tooltip>
                          )}

                          {/* se o item for concluído */}
                          {isDone && (
                            <Tooltip
                              title={`Concluído em
                            ${moment(new Date(isDone?.created_at)).format(
                              "DD/MM/YYYY HH:mm"
                            )} por ${
                                usuarios?.find(
                                  (usr: any) => usr._id === isDone.user_id
                                ).name
                              }`}
                            >
                              <span
                                style={{
                                  color: "green",
                                  marginRight: "0.5rem",
                                }}
                              >
                                <CheckOutlined style={{ color: "green" }} />{" "}
                                (Concluído)
                              </span>
                            </Tooltip>
                          )}
                        </Tooltip>
                      )}
                      {log.description?.match("Concluído") && (
                        <Tooltip title="Item concluído">
                          <span
                            style={{ color: "green", marginRight: "0.5rem" }}
                          >
                            <CheckOutlined style={{ color: "green" }} />{" "}
                            (Concluído)
                          </span>
                        </Tooltip>
                      )}
                      <span
                        style={
                          novoPrazo ? { textDecoration: "line-through" } : {}
                        }
                      >
                        {log.description && log.description.length > 200 ? (
                          <>
                            {verMais === log._id
                              ? log.description
                                  .replace(/\s*\(Prazo:.*?\)/, "")
                                  ?.replace("(Concluído)", "")
                                  ?.replace(/\s*\(Novo Prazo:.*?\)/, "")
                              : truncateDescription(
                                  log.description
                                    .replace(/\s*\(Prazo:.*?\)/, "")
                                    ?.replace("(Concluído)", "")
                                    ?.replace(/\s*\(Novo Prazo:.*?\)/, ""),
                                  200
                                )}
                            {verMais === log._id ? (
                              <Button
                                type="link"
                                onClick={() => {
                                  setVerMais(null);
                                }}
                              >
                                Ver menos
                              </Button>
                            ) : (
                              <Button
                                type="link"
                                onClick={() => {
                                  setVerMais(log._id);
                                }}
                              >
                                Ver mensagem completa
                              </Button>
                            )}
                          </>
                        ) : (
                          log.description
                            ?.replace(/\s*\(Prazo:.*?\)/, "")
                            ?.replace("(Concluído)", "")
                            ?.replace(/\s*\(Novo Prazo:.*?\)/, "")
                        )}
                      </span>
                    </p>
                  </Timeline.Item>
                );
              })}
          </Timeline>
          {filteredLogs && visibleLogs < filteredLogs?.length && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1rem 0",
              }}
            >
              <Button onClick={handleLoadMore} type="primary">
                Mais registros
              </Button>
            </div>
          )}

          <Task
            setShowModal={setIsVerTarefaModalVisible}
            showModal={isVerTarefaModalVisible}
          />
        </div>
      )}
    </div>
  );
};

export default TimelineGeral;
