import React, { useState } from "react";
import { Button, Input, Table } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import TaskBlocks from "../../../../components/Tarefas/TaskBlocks/TaskBlocks";

const { Search } = Input;

const VisaoGeralTarefas = () => {
  const { moduloTask } = React.useContext(GlobalContext) as GlobalContextType;

  return (
    <div className="">
      <BackHeader title="Visão geral de tarefas" />
      <TaskBlocks />
    </div>
  );
};

export default VisaoGeralTarefas;
