import { useParams } from "react-router-dom";
import React, { ReactNode, useEffect, useState } from "react";
import "./styles.css";
import { Checkbox, Tabs } from "antd";
import Analise from "./components/analise";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import PdfDownload from "../../../../components/PdfDownload/PdfDownload";
import api from "../../../../services/api";
import {
  CompassOutlined,
  MailOutlined,
  PhoneOutlined,
  SearchOutlined,
  WarningFilled,
} from "@ant-design/icons";
import CapaRelBlock from "../../../../assets/images/CapaRelBlock.jpeg";
import LogoSeusdados from "../../../../assets/images/LogoSeusDados.png";
import moment from "moment";
import { Bar, BarChart, CartesianGrid, Legend, XAxis, YAxis } from "recharts";

interface Response {
  questao_id: string;
  resposta: string[];
}

interface Questao {
  _id: string; // Make sure this matches the actual type from your API
  questao: string;
  ordem: number;
  tipo: string;
}

const AnaliseFornecedor = () => {
  const params = useParams();
  const { grauRiscos, userInfo, fornecedores, getFornecedores } =
    React.useContext(GlobalContext) as GlobalContextType;

  const [allRespostas, setAllRespostas] = useState<any>([]);
  const [mergedData, setMergedData] = useState<
    { questao: string; ordem: number; respostas: string[] }[]
  >([]);
  const [dataBar, setDataBar] = useState<any>([]);
  const [porcentagemConforme, setPorcentagemConforme] = useState<any>(null);
  const [dataAll, setDataAll] = useState<any>([]);
  const [metrica, setMetrica] = useState<any>(null);
  const [tratamentoDados, setTratamentoDados] = useState<any>(null);

  useEffect(() => {
    async function fetchData() {
      if (grauRiscos[0]) {
        try {
          const response = await api.get(
            `/respostasforn/${params.questionarioid}`
          );

          const verificaExistenciaTratamento = async () => {
            if (
              response.data.respostas.find(
                (q: any) => q.questao_id === "660fee5de65c9279bff20fa8"
              )
            ) {
              if (
                response.data.respostas
                  .find((q: any) => q.questao_id === "660fee5de65c9279bff20fa8")
                  ?.resposta.includes("Sim")
              ) {
                setTratamentoDados(false);
              } else if (
                response.data.respostas
                  .find((q: any) => q.questao_id === "660fee5de65c9279bff20fa8")
                  ?.resposta.includes("Não")
              ) {
                setTratamentoDados(true);
              }
            } else {
              setTratamentoDados(true);
            }
          };

          verificaExistenciaTratamento();

          const questoesResponse = await api.get("/questoes");
          const questoes: Questao[] = questoesResponse.data;

          const mergedDataArray = response.data.respostas.map(
            (resposta: Response) => {
              const questao = questoes.find(
                (q: Questao) => q._id === resposta.questao_id
              );

              return {
                ...questao,
                respostas: resposta.resposta,
                graus: grauRiscos.filter(
                  (g: any) =>
                    g.questao_id.toString() === questao?._id.toString()
                ),
              };
            }
          );

          const sortedMergedData = mergedDataArray.sort(
            (a: any, b: any) => a.ordem - b.ordem
          );

          let allRespData: any = [];

          sortedMergedData
            .filter((r: any) => r._id)
            .forEach((questao: any) => {
              if (questao.respostas && questao.respostas[0]) {
                questao.respostas.map((item: any) => {
                  if (
                    allRespData.filter(
                      (item: any) =>
                        item._id.toString() === questao._id.toString()
                    ).length !== 0
                  ) {
                    return;
                  }
                  return allRespData.push({
                    ...questao,
                    resposta: item,
                    grau: grauRiscos
                      .filter(
                        (gr: any) =>
                          gr.questao_id.toString() === questao._id.toString()
                      )
                      .filter((gr: any) => gr.value.includes(item))[0]?.grau,
                  });
                });
              }
            });

          setAllRespostas(allRespData);
          setMergedData(sortedMergedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
    fetchData();
  }, [params.questionarioid, grauRiscos]);

  useEffect(() => {
    if (allRespostas && allRespostas[0]) {
      let meio =
        allRespostas.filter(
          (r: any) =>
            (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
            r._id !== "64ee165a41736da1927a46b7" && // 12
            r._id !== "64ee165a41736da1927a46c7" // 28
        ).length / 2;

      // acrescentar calculo da pergunta 12 (meio ponto para cada alternativa assinalada)
      if (allRespostas.find((r: any) => r._id === "64ee165a41736da1927a46b7")) {
        meio =
          meio +
          allRespostas
            .find((r: any) => r._id === "64ee165a41736da1927a46b7")
            ?.respostas?.filter((r: any) => r !== "Não sei informar")?.length /
            2;
      }

      // acrescentar calculo da pergunta 28 (meio ponto para cada alternativa assinalada)
      if (allRespostas.find((r: any) => r._id === "64ee165a41736da1927a46c7")) {
        meio =
          meio +
          allRespostas
            .find((r: any) => r._id === "64ee165a41736da1927a46c7")
            ?.respostas?.filter((r: any) => r !== "Nenhuma Ferramenta")
            ?.length /
            2;
      }

      const conforme = allRespostas.filter((r: any) => r.grau === "sim").length;

      const somaMeioConforme = meio + conforme;

      const pontuacaopergunta7 =
        mergedData.find((data: any) => data?._id === "64ee165a41736da1927a46b2")
          ?.respostas?.length || 0;

      setPorcentagemConforme(
        metrica ? metrica : (somaMeioConforme / (21 + pontuacaopergunta7)) * 100
      );
      setDataAll([
        {
          name: "Conforme",
          value: allRespostas.filter((r: any) => r.grau === "sim").length,
          fill: "#43CB03",
        },
        {
          name: "Não Conforme",
          value: allRespostas.filter((r: any) => r.grau === "nao").length,
          fill: "#FE0C0C",
        },
        {
          name: "Pontos de Atenção",
          value: allRespostas.filter(
            (r: any) => r.grau === "meio" || r.grau === "Ponto de Atenção"
          ).length,
          fill: "blue",
        },
      ]);

      setDataBar([
        {
          categoria: "Informações gerais da empresa",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria === "Informações gerais da empresa"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria === "Informações gerais da empresa"
          ).length,
        },
        {
          categoria: "Encarregado de Proteção de dados (DPO)",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria === "Encarregado de Proteção de dados (DPO)"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria === "Encarregado de Proteção de dados (DPO)"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria === "Encarregado de Proteção de dados (DPO)"
          ).length,
        },
        {
          categoria: "Identificação dos tratamentos de dados pessoais",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria === "Identificação dos tratamentos de dados pessoais"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria === "Identificação dos tratamentos de dados pessoais"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria === "Identificação dos tratamentos de dados pessoais"
          ).length,
        },
        {
          categoria: "Conformidade à Privacidade e Proteção de dados (LGPD)",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria ===
                "Conformidade à Privacidade e Proteção de dados (LGPD)"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria ===
                "Conformidade à Privacidade e Proteção de dados (LGPD)"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria ===
                "Conformidade à Privacidade e Proteção de dados (LGPD)"
          ).length,
        },
        {
          categoria: "Atendimento aos direitos do titular de dados",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria === "Atendimento aos direitos do titular de dados"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria === "Atendimento aos direitos do titular de dados"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria === "Atendimento aos direitos do titular de dados"
          ).length,
        },
        {
          categoria: "Tecnologia da Informação",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" && r.categoria === "Tecnologia da Informação"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" && r.categoria === "Tecnologia da Informação"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria === "Tecnologia da Informação"
          ).length,
        },
        {
          categoria: "Segurança da Informação",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" && r.categoria === "Segurança da Informação"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" && r.categoria === "Segurança da Informação"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria === "Segurança da Informação"
          ).length,
        },
        {
          categoria:
            "Histórico de incidentes de segurança envolvendo dados pessoais",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria ===
                "Histórico de incidentes de segurança envolvendo dados pessoais"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria ===
                "Histórico de incidentes de segurança envolvendo dados pessoais"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria ===
                "Histórico de incidentes de segurança envolvendo dados pessoais"
          ).length,
        },
        {
          categoria: "Cultura de Segurança da informação",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria === "Cultura de Segurança da informação"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria === "Cultura de Segurança da informação"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria === "Cultura de Segurança da informação"
          ).length,
        },
        {
          categoria: "Treinamento e conscientização",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria === "Treinamento e conscientização"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria === "Treinamento e conscientização"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria === "Treinamento e conscientização"
          ).length,
        },
        {
          categoria: "Gerenciamento de Banco de dados",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria === "Gerenciamento de Banco de dados"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria === "Gerenciamento de Banco de dados"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria === "Gerenciamento de Banco de dados"
          ).length,
        },
        {
          categoria: "Transferência internacional de dados",
          Conformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "sim" &&
              r.categoria === "Transferência internacional de dados"
          ).length,
          Inconformidades: allRespostas.filter(
            (r: any) =>
              r.grau === "nao" &&
              r.categoria === "Transferência internacional de dados"
          ).length,
          "Pontos de Atenção": allRespostas.filter(
            (r: any) =>
              (r.grau === "meio" || r.grau === "Ponto de Atenção") &&
              r.categoria === "Transferência internacional de dados"
          ).length,
        },
      ]);
    }
  }, [allRespostas]);

  useEffect(() => {
    params.clienteid && !fornecedores?.[0] && getFornecedores(params.clienteid);
  }, [params, fornecedores]);

  const categorias = [
    "Informações gerais da empresa",
    "Encarregado de Proteção de dados (DPO)",
    "Identificação dos tratamentos de dados pessoais",
    "Conformidade à Privacidade e Proteção de dados (LGPD)",
    "Atendimento aos direitos do titular de dados",
    "Tecnologia da Informação",
    "Segurança da Informação",
    "Treinamento e conscientização",
    "Cultura de Segurança da informação",
    "Histórico de incidentes de segurança envolvendo dados pessoais",
    "Gerenciamento de Banco de dados",
    "Transferência internacional de dados",
  ];

  function encontrarIndicePorValor(valorProcurado: any) {
    // Utilize a função map para percorrer o array
    // A função map retorna um novo array com os índices dos objetos que correspondem ao valor procurado
    const indicesEncontrados = allRespostas
      .sort((a: any, b: any) => a.ordem - b.ordem)
      .map((objeto: any, indice: number) => {
        if (objeto === valorProcurado) {
          return indice + 1;
        }
        return null; // Retorna null para os objetos que não correspondem ao valor procurado
      });

    // Filtra os índices válidos (diferentes de null)
    const indicesValidos = indicesEncontrados.filter(
      (indice: number) => indice !== null
    );

    return indicesValidos;
  }

  const content: JSX.Element =
    fornecedores?.filter(
      (f: any) => f._id.toString() === params.fornecedorid
    )[0] &&
    !isNaN(porcentagemConforme) &&
    porcentagemConforme > 0 ? (
      <div className="relatorio">
        <div className="capa" style={{ pageBreakAfter: "always" }}>
          <h3>{"Relatório de conformidade".toUpperCase()}</h3>
          <div className="linha_capa" />
          <h1 className="capa__title">
            Proteção de
            <br />
            <b>DADOS</b>
          </h1>
          <img alt="Capa" src={CapaRelBlock} />
          <p>
            {moment(
              new Date(
                fornecedores?.filter(
                  (f: any) => f._id === params.fornecedorid
                )[0]?.updated_at
              )
            ).format("YY")}
          </p>
        </div>
        <div className="page2" style={{ pageBreakAfter: "always" }}>
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          ></div>
          <h1 className="title2">
            Para
            <br />
            <b>Começar</b>
          </h1>
          <div className="rows">
            <div className="col1">
              <p className="col1_title">PREPARADO POR:</p>
              <b>
                {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
              </b>
              <p className="col1_title">RESPONSÁVEL:</p>
              <b>MARCELO FATTORI</b>
              <img className="relatorio__seusdados-logo" src={LogoSeusdados} />
            </div>
            <div className="col2">
              <h4>Por que isso é importante:</h4>
              <p>
                A Lei Geral de Proteção de Dados Pessoais (LGPD) é uma lei
                federal que regulamenta o tratamento de dados pessoais de
                pessoas físicas no Brasil. Estar em conformidade com a LGPD é
                importante por três motivos:
              </p>
              <ul>
                <li>
                  Responsabilidade legal: empresas que não cumprem a LGPD podem
                  ser multadas em até 2% do faturamento anual, limitadas a R$ 50
                  milhões.
                </li>
                <li>
                  Ética e transparência: as empresas têm a responsabilidade de
                  proteger a privacidade e a segurança das pessoas ao coletar e
                  processar dados pessoais.
                </li>
                <li>
                  Imagem e reputação: empresas que demonstram compromisso com a
                  proteção de dados pessoais ganham a confiança de seus clientes
                  e parceiros.
                </li>
              </ul>
              <p>
                Um diagnóstico de avaliação de conformidade é um processo que
                avalia o estado de conformidade de uma empresa com a LGPD. Essa
                informação é valiosa para a elaboração de um plano de
                continuidade de negócios, pois permite identificar áreas na qual
                a empresa está em conformidade e áreas que necessitam de
                melhorias. Quando adequadas à LGPD, as empresas reduzem o risco
                de sofrerem incidentes de segurança que podem comprometer seus
                dados pessoais.
              </p>
              <p
                style={{
                  fontSize: "16px",
                  marginTop: "1rem",
                }}
              >
                Palavras-chave: LGPD, conformidade, plano de continuidade de
                negócios, diagnóstico
              </p>
            </div>
          </div>
        </div>
        <div className="page3" style={{ pageBreakAfter: "always" }}>
          <h1 className="title2">
            Objetivo &
            <br />
            <b>metodologia</b>
          </h1>
          <div className="rows">
            <div className="col1">
              <p className="col1_title">PREPARADO POR:</p>
              <b>
                {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
              </b>
              <p className="col1_title">RESPONSÁVEL:</p>
              <b>MARCELO FATTORI</b>
              <img className="relatorio__seusdados-logo" src={LogoSeusdados} />
            </div>
            <div className="col2">
              <h4>Resumo</h4>
              <p>
                O relatório apresenta os resultados do diagnóstico de nível de
                conformidade em proteção de dados pessoais realizado na empresa
                <b>
                  {" "}
                  {
                    fornecedores?.filter(
                      (f: any) => f._id === params.fornecedorid
                    )[0]?.nome
                  }
                </b>
                , pela{" "}
                <b>SEUSDADOS CONSULTORIA EM PROTEÇÃO DE DADOS LIMITADA</b> em{" "}
                <b>
                  {moment(
                    new Date(
                      fornecedores?.filter(
                        (f: any) => f._id === params.fornecedorid
                      )[0]?.updated_at
                    )
                  ).format("DD/MM/YYYY")}
                </b>
                .
              </p>
              <h4>Objetivo</h4>
              <p
              // style={{ pageBreakAfter: "always" }}
              >
                O objetivo do diagnóstico foi avaliar o estado de conformidade
                da empresa com a Lei Geral de Proteção de Dados Pessoais (LGPD).
              </p>
              <h4>Metodologia</h4>
              <p>
                A metodologia de avaliação utilizada foi a autoavaliação de
                controles (do inglês Control Self Assessment - CSA). Essa
                metodologia é baseada no preenchimento de um questionário
                eletrônico por gestores das organizações, cujas questões avaliam
                o estado de conformidade das organizações com as regras e
                obrigações da LGPD.
              </p>
              <p>
                A autoavaliação de controles é uma metodologia eficaz para
                avaliar a conformidade com a LGPD por vários motivos:
              </p>
              <ul>
                <li>
                  Rapidez e eficiência: Pode ser realizada sem apoio de uma
                  equipe de auditores externos.
                </li>
                <li>
                  Flixibilidade: Pode ser adaptada às necessidades específicas
                  de cada organização.
                </li>
                <li>
                  Eficácia para identificar áreas de melhoria: Permite verificar
                  quais controles já foram implementados e quais ainda precisam
                  ser implementados.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="page4" style={{ pageBreakAfter: "always" }}>
          <h1 className="title2">
            Indicadores &
            <br />
            <b>racional</b>
          </h1>
          <div className="rows">
            <div className="col1">
              <p className="col1_title">PREPARADO POR:</p>
              <b>
                {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
              </b>
              <p className="col1_title">RESPONSÁVEL:</p>
              <b>MARCELO FATTORI</b>
              <img className="relatorio__seusdados-logo" src={LogoSeusdados} />
            </div>
            <div className="col2">
              <h4>Estrutura da avaliação diagnóstica:</h4>
              <p>
                Nove aspectos fundamentais foram avaliados para medir o
                comprometimento da empresa com as regras de proteção de dados:
              </p>
              <ul>
                <li>
                  <b>Informações Gerais da Empresa:</b> Atividade desenvolvida e
                  hipóteses de tratamento de dados;
                </li>
                <li>
                  <b>Encarregado de Proteção de Dados (DPO):</b> Existência de
                  DPO nomeado e devidamente capacitado;
                </li>
                <li>
                  <b>Identificação de Tratamentos de Dados Pessoais:</b>{" "}
                  Mapeamento dos processos de tratamentos de dados pessoais;
                </li>
                <li>
                  <b>Conformidade à LGPD:</b> Observância dos princípios da
                  LGPD, bases legais, tipos de dados e prazos de armazenamento;
                </li>
                <li>
                  <b>Atendimento aos Direitos do Titular de Dados:</b>{" "}
                  Cumprimento das regras relacionadas aos direitos do titular de
                  dados;
                </li>
                <li>
                  <b>Tecnologia da Informação:</b> Infraestrutura de TI segura e
                  confiável;
                </li>
                <li>
                  <b>Segurança da Informação:</b> Política de segurança da
                  informação, implementação e aderência a estrutura e riscos;
                </li>
                <li>
                  <b>Treinamento e Conscientização:</b> Treinamentos periódicos
                  sobre proteção de dados pessoais;
                </li>
                <li>
                  <b>Histórico de Incidentes de Segurança:</b> Histórico de
                  incidentes e seu impacto nas atividades atuais;
                </li>
                <li>
                  <b>Banco de Dados:</b> Estrutura, gerenciamento, controles de
                  acesso e informações relevantes;
                </li>
                <li>
                  <b>Transferência Internacional de Dados:</b> Avaliação dos
                  processos de transferência internacional de dados e sua
                  regularidade.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="page5" style={{ pageBreakAfter: "always" }}>
          <h1 className="title2">
            Nível de
            <br />
            <b>conformidade</b>
          </h1>
          <div className="rows">
            <div className="col1">
              <p className="col1_title">PREPARADO POR:</p>
              <b>
                {"Seusdados Consultoria em Proteção de Dados Limitada".toUpperCase()}
              </b>
              <p className="col1_title">RESPONSÁVEL:</p>
              <b>MARCELO FATTORI</b>
              <img className="relatorio__seusdados-logo" src={LogoSeusdados} />
            </div>
            <div className="col2">
              <h4>Ferramenta de Avaliação de Conformidade:</h4>

              <p>
                Para consolidar os dados obtidos e permitir a comparação das
                organizações auditadas, foi elaborado um indicador de
                conformidade. Esse indicador é composto por um subconjunto de
                questões que avaliam o estado de conformidade das organizações
                com as regras e obrigações da LGPD.
              </p>
              <br />
              <p>
                O cálculo do indicador envolve atribuição de um valor para cada
                opção de respostas ("Conforme", "Ponto de Atenção" ou "Não
                conformidade"). O valor do indicador é obtido pela média
                aritmética ponderada entre as opções de respostas identificadas
                acima, de forma que conformidades/inconformidades detém o dobro
                do peso ao serem comparados com os pontos de atenção, haja vista
                que revelam uma compatibilidade ou contrariedade direta à LGPD.
              </p>
              <br />
              <p>
                <b>Pontos de atenção</b> identificam processos ou atividades que
                exigem uma análise mais detalhada devido a possíveis desafios ou
                preocupações identificadas. Enquanto não necessariamente
                representam violações diretas à LGPD, esses pontos podem
                contrariar as melhores práticas de segurança da informação,
                padrões de mercado ou normas reconhecidas, como as normas ISO
                ABNT, conforme detalhado no relatório formal. Eles alertam para
                a necessidade de atenção e possíveis ajustes para garantir a
                conformidade e a eficácia geral do sistema.
              </p>
              <br />
              <p>
                A partir dos valores do indicador de <b>não conformidade</b>,
                foram definidos três níveis de adequação à LGPD:
              </p>
              <ul>
                <li>
                  <b>Alto:</b> A organização não está em conformidade com a LGPD
                  e apresenta baixo nível de maturidade em relação à proteção de
                  dados;
                </li>
                <li>
                  <b>Médio:</b> A organização está em processo de adequação à
                  LGPD e apresenta um nível de maturidade inicial;
                </li>
                <li>
                  <b>Baixo:</b> A organização está em conformidade com a maioria
                  das regras da LGPD e apresenta um nível de maturidade médio.
                </li>
              </ul>
            </div>
          </div>
        </div>
        {!allRespostas.find(
          (perg: any) =>
            perg._id === "660fee5de65c9279bff20fa8" &&
            perg.respostas.includes("Sim")
        ) && (
          <div className="page6" style={{ pageBreakBefore: "always" }}>
            <h1 className="title2">
              Resultados &
              <br />
              <b>conformidade</b>
            </h1>

            <div className="rows_chart">
              <div className="rows_chart--col1">
                <p style={{ paddingTop: "3rem" }}>
                  Baseado nas entrevistas realizadas com os colaboradores da
                  organização:
                </p>
              </div>
              <div className="rows_chart--col2">
                <div
                  className="rows_chart--col2__nivel"
                  style={{
                    width: 50,
                    height: 200,
                    background:
                      "#99B6FF linear-gradient(180deg, #8B5692 0%, #9785CC 50%,  #99B6FF 100%)",
                  }}
                  translate="no"
                >
                  <p
                    style={{
                      position: "relative",
                      top: 90 + "px",
                      marginTop: "-29px",
                      left: "78px",
                      color:
                        porcentagemConforme > 33 && porcentagemConforme < 66
                          ? "#9785CC"
                          : porcentagemConforme >= 66
                          ? "#99B6FF"
                          : "#8B5692",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                    translate="no"
                  >
                    {porcentagemConforme >= 66 && "BAIXO"}
                    {porcentagemConforme > 33 &&
                      porcentagemConforme < 66 &&
                      "MÉDIO"}
                    {porcentagemConforme <= 33 && "ALTO"}
                  </p>
                  <p
                    style={{
                      marginTop: "-51px",
                      marginLeft: "8px",
                    }}
                    translate="no"
                  >
                    Risco
                  </p>
                  <div
                    style={{
                      width: "70px",
                      height: "2px",
                      marginTop: "-2px",
                      background: "#000",
                      opacity: 0.3,
                      marginLeft: "-10px",
                    }}
                  />
                  <div
                    style={{
                      width: "70px",
                      height: "2px",
                      background: "#000",
                      marginLeft: "-10px",
                      marginTop: `calc(${porcentagemConforme}px*2)`,
                    }}
                  >
                    <div className="arrow right" style={{ top: "-3.5px" }} />
                    <div
                      className="arrow left"
                      style={{ top: "-12.9px", left: "63px" }}
                    />
                  </div>
                  <div
                    style={{
                      width: "70px",
                      height: "2px",
                      background: "#000",
                      opacity: 0.3,
                      marginLeft: "-10px",
                      marginTop: `calc(196px - calc(${porcentagemConforme}px*2))`,
                    }}
                  />
                </div>
              </div>
            </div>

            {porcentagemConforme >= 66 && (
              <p
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                  marginTop: "6rem",
                }}
              >
                A empresa{" "}
                <b>
                  {
                    fornecedores?.filter(
                      (f: any) => f._id === params.fornecedorid
                    )[0]?.nome
                  }
                </b>{" "}
                foi avaliada como apresentando um <b>baixo</b> nível de não
                conformidade em relação à LGPD. Este resultado indica que a
                organização está em conformidade com a maioria das regras,
                refletindo uma maturidade em proteção de dados. Recomenda-se a
                contínua vigilância e aprimoramento para garantir a robustez
                contínua nas práticas de proteção de dados.
              </p>
            )}
            {porcentagemConforme > 33 && porcentagemConforme < 66 && (
              <p
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                  marginTop: "6rem",
                }}
              >
                A empresa{" "}
                <b>
                  {
                    fornecedores?.filter(
                      (f: any) => f._id === params.fornecedorid
                    )[0]?.nome
                  }
                </b>{" "}
                demonstrou um nível de conformidade considerado <b>"Médio"</b>{" "}
                no diagnóstico de proteção de dados, sinalizando um processo de
                adequação à LGPD e um estágio inicial de maturidade em proteção
                de dados. É essencial manter o impulso e direcionar esforços
                para áreas identificadas como oportunidades de melhoria.
              </p>
            )}
            {porcentagemConforme <= 33 && (
              <p
                style={{
                  fontSize: "24px",
                  textAlign: "center",
                  marginTop: "6rem",
                }}
              >
                A empresa{" "}
                <b>
                  {
                    fornecedores?.filter(
                      (f: any) => f._id === params.fornecedorid
                    )[0]?.nome
                  }
                </b>{" "}
                demonstrou um alto nível de não conformidade, indicando que há
                desafios significativos em atender às regras e obrigações da
                LGPD. Este resultado aponta para a necessidade urgente de
                medidas corretivas para mitigar riscos e melhorar a postura
                geral de proteção de dados.
              </p>
            )}
            <img
              className="relatorio__seusdados-logo"
              src={LogoSeusdados}
              style={{
                bottom: "0",
                marginTop: "12rem",
                // paddingBottom: "2rem",
              }}
              alt="logo seusdados"
            />
          </div>
        )}

        <div className="page7" style={{ pageBreakAfter: "always" }}>
          <h1
            className="title2"
            style={{
              paddingTop: "1.25rem !important",
            }}
          >
            Análises &
            <br />
            <b>plano de ação</b>
          </h1>

          <div className="capa_planoacao--bg">
            {dataBar[0] && tratamentoDados && (
              // <BarChart
              //   width={600}
              //   height={650}
              //   data={dataBar.filter(
              //     (data: any) => data.Inconformidades + data.Conformidades !== 0
              //   )}
              //   layout="vertical"
              //   margin={{
              //     top: 100,
              //     right: 30,
              //     left: 20,
              //     bottom: 5,
              //   }}
              // >
              //   <XAxis type="number" />
              //   <YAxis dataKey="categoria" type="category" width={300} />
              //   <Legend
              //     verticalAlign="top"
              //     wrapperStyle={{
              //       transform: "scale(1) translateY(-50px)",
              //       // paddingBottom: "6rem",
              //     }} // Ajuste o tamanho da fonte conforme necessário
              //   />
              //   <Bar
              //     isAnimationActive={false}
              //     dataKey="Conformidades"
              //     fill="#99B6FF"
              //   />
              //   <Bar
              //     isAnimationActive={false}
              //     dataKey="Inconformidades"
              //     fill="#8B5692"
              //   />
              //   <Bar
              //     dataKey="Pontos de Atenção"
              //     fill="#1677ff"
              //   />
              // </BarChart>
              <BarChart
                width={600}
                height={400}
                data={dataBar.filter(
                  (data: any) => data.Inconformidades + data.Conformidades !== 0
                )}
                style={{ marginTop: "8rem", marginLeft: "2rem" }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis />
                <XAxis
                  dataKey="categoria"
                  axisLine={true}
                  tickLine={false}
                  interval={0}
                  height={1}
                />
                <Bar
                  isAnimationActive={false}
                  dataKey="Conformidades"
                  fill="#43CB03"
                />
                <Bar
                  isAnimationActive={false}
                  dataKey="Inconformidades"
                  fill="#FE0C0C"
                />
                <Bar
                  isAnimationActive={false}
                  dataKey="Pontos de Atenção"
                  fill="#ffa600"
                />
                <Legend
                  verticalAlign="bottom"
                  wrapperStyle={{
                    transform: "scale(1) translateY(50px) translateX(20px)",
                  }}
                />
              </BarChart>
            )}
          </div>
        </div>
        <div>
          {(tratamentoDados
            ? categorias
            : [
                "Informações gerais da empresa",
                "Encarregado de Proteção de dados (DPO)",
                "Identificação dos tratamentos de dados pessoais",
              ]
          )
            .filter((cat: any) =>
              allRespostas.find((r: any) => r.categoria === cat)
            )
            .map((cat: string) => (
              <div style={{ pageBreakAfter: "always" }}>
                <div className="relatorio__headerCap">
                  <h2>{cat}</h2>
                </div>
                <div className="respostas__containner">
                  {!allRespostas?.filter(
                    (r: any) => r.categoria === cat
                  )[0] && <h3>Sem respostas</h3>}
                  {allRespostas && (
                    <div className="respostas__pergunta">
                      {allRespostas
                        ?.filter((r: any) => r.categoria === cat)
                        .map((pergunta: any) => {
                          return (
                            <>
                              <h3
                                style={{
                                  marginBottom: "1rem",
                                  marginTop: "1rem",
                                }}
                              >
                                Pergunta:
                              </h3>
                              <div
                                key={pergunta + "analiseResp"}
                                style={{ marginBottom: "1.5rem" }}
                              >
                                <label
                                  className="label"
                                  style={{ marginLeft: "1rem" }}
                                >
                                  <b>{encontrarIndicePorValor(pergunta)}. </b>
                                  {`${pergunta.questao}`}
                                  {!pergunta.graus[0] && (
                                    <b
                                      className={
                                        "respostas__pergunta--label__blue"
                                      }
                                    >
                                      {" "}
                                      Conhecimento de Processo
                                    </b>
                                  )}
                                </label>

                                <div style={{ marginLeft: "2rem" }}>
                                  {pergunta.tipo !== "input"
                                    ? pergunta.opcoes
                                        ?.filter((opt: string) =>
                                          pergunta.ordem === 8
                                            ? allRespostas
                                                ?.find(
                                                  (r: any) => r.ordem === 7
                                                )
                                                ?.respostas?.includes(opt)
                                            : true
                                        )
                                        ?.map((opt: string) => (
                                          <p>
                                            <Checkbox
                                              checked={pergunta.respostas.includes(
                                                opt
                                              )}
                                              style={{
                                                marginRight: "0.5rem",
                                              }}
                                            />
                                            {opt}{" "}
                                            {pergunta.graus[0] &&
                                              pergunta.respostas.includes(
                                                opt
                                              ) && (
                                                <b
                                                  className={
                                                    pergunta.graus?.filter(
                                                      (g: any) =>
                                                        g.value.includes(opt)
                                                    )[0]?.grau === "sim"
                                                      ? pergunta.respostas.includes(
                                                          opt
                                                        )
                                                        ? "respostas__pergunta--label__conforme"
                                                        : "respostas__pergunta--label__inconforme"
                                                      : pergunta.graus?.filter(
                                                          (g: any) =>
                                                            g.value.includes(
                                                              opt
                                                            )
                                                        )[0]?.grau === "nao"
                                                      ? pergunta.respostas.includes(
                                                          opt
                                                        )
                                                        ? "respostas__pergunta--label__inconforme"
                                                        : "respostas__pergunta--label__conforme"
                                                      : "respostas__pergunta--label__blue"
                                                  }
                                                >
                                                  {pergunta.graus?.filter(
                                                    (g: any) =>
                                                      g.value.includes(opt)
                                                  )[0]?.grau === "sim" ? (
                                                    pergunta.respostas.includes(
                                                      opt
                                                    ) ? (
                                                      "Conforme"
                                                    ) : [
                                                        11, 14, 17, 20, 21, 23,
                                                        24, 25, 26, 27, 28, 30,
                                                        31, 34, 35, 39,
                                                      ].includes(
                                                        pergunta?.ordem
                                                      ) ? (
                                                      <span
                                                        style={{
                                                          color: "#ffa600",
                                                        }}
                                                      >
                                                        <WarningFilled />
                                                        <span
                                                          style={{
                                                            fontSize: "0.8rem",
                                                            marginLeft:
                                                              "0.25rem",
                                                          }}
                                                        >
                                                          Ponto de Atenção
                                                        </span>
                                                      </span>
                                                    ) : (
                                                      "Não conforme"
                                                    )
                                                  ) : pergunta.graus?.filter(
                                                      (g: any) =>
                                                        g.value.includes(opt)
                                                    )[0]?.grau === "nao" ? (
                                                    pergunta.respostas.includes(
                                                      opt
                                                    ) ? (
                                                      [
                                                        11, 14, 17, 20, 18, 21,
                                                        23, 24, 25, 26, 27, 28,
                                                        30, 31, 34, 35, 39,
                                                      ].includes(
                                                        pergunta?.ordem
                                                      ) ? (
                                                        <span
                                                          style={{
                                                            color: "#ffa600",
                                                          }}
                                                        >
                                                          <WarningFilled />
                                                          <span
                                                            style={{
                                                              fontSize:
                                                                "0.8rem",
                                                              marginLeft:
                                                                "0.25rem",
                                                            }}
                                                          >
                                                            Ponto de Atenção
                                                          </span>
                                                        </span>
                                                      ) : (
                                                        "Não conforme"
                                                      )
                                                    ) : (
                                                      "Conforme"
                                                    )
                                                  ) : pergunta.graus?.filter(
                                                      (g: any) =>
                                                        g.value.includes(opt)
                                                    )[0]?.grau ===
                                                    "Ponto de Atenção" ? (
                                                    <span
                                                      style={{
                                                        color: "#ffa600",
                                                      }}
                                                    >
                                                      <WarningFilled />
                                                      <span
                                                        style={{
                                                          fontSize: "0.8rem",
                                                          marginLeft: "0.25rem",
                                                        }}
                                                      >
                                                        Ponto de Atenção
                                                      </span>
                                                    </span>
                                                  ) : (
                                                    <>
                                                      {pergunta.graus
                                                        ?.filter((g: any) =>
                                                          g.value.includes(opt)
                                                        )[0]
                                                        ?.grau.replace(
                                                          "meio",
                                                          ""
                                                        )}{" "}
                                                      {pergunta.graus
                                                        ?.filter((g: any) =>
                                                          g.value.includes(opt)
                                                        )[0]
                                                        ?.grau.includes(
                                                          "Alto Risco"
                                                        ) && (
                                                        <span
                                                          style={{
                                                            color: "#000",
                                                            fontWeight:
                                                              "normal",
                                                            opacity: 0.4,
                                                            fontSize: "0.8rem",
                                                          }}
                                                        >
                                                          (art. 4°, Res. 
                                                          CD/ANPD n°02/2022)
                                                        </span>
                                                      )}
                                                    </>
                                                  )}
                                                </b>
                                              )}
                                            {[8, 12, 28].includes(
                                              pergunta.ordem
                                            ) &&
                                              pergunta.graus[0] && (
                                                <b
                                                  className={
                                                    pergunta.graus?.filter(
                                                      (g: any) =>
                                                        g.value.includes(opt)
                                                    )[0]?.grau === "sim"
                                                      ? pergunta.respostas.includes(
                                                          opt
                                                        )
                                                        ? "respostas__pergunta--label__conforme"
                                                        : "respostas__pergunta--label__inconforme"
                                                      : pergunta.graus?.filter(
                                                          (g: any) =>
                                                            g.value.includes(
                                                              opt
                                                            )
                                                        )[0]?.grau === "nao"
                                                      ? pergunta.respostas.includes(
                                                          opt
                                                        )
                                                        ? "respostas__pergunta--label__inconforme"
                                                        : "respostas__pergunta--label__conforme"
                                                      : "respostas__pergunta--label__blue"
                                                  }
                                                >
                                                  {pergunta.graus?.filter(
                                                    (g: any) =>
                                                      g.value.includes(opt)
                                                  )[0]?.grau === "sim" ? (
                                                    pergunta.respostas.includes(
                                                      opt
                                                    ) ? (
                                                      pergunta.ordem !== 8 ? (
                                                        "Conforme"
                                                      ) : (
                                                        ""
                                                      )
                                                    ) : [
                                                        11, 14, 17, 20, 23, 24,
                                                        25, 26, 27, 28, 30, 31,
                                                        34, 35, 39,
                                                      ].includes(
                                                        pergunta?.ordem
                                                      ) ? (
                                                      <span
                                                        style={{
                                                          color: "#ffa600",
                                                        }}
                                                      >
                                                        <WarningFilled />
                                                        <span
                                                          style={{
                                                            fontSize: "0.8rem",
                                                            marginLeft:
                                                              "0.25rem",
                                                          }}
                                                        >
                                                          Ponto de Atenção
                                                        </span>
                                                      </span>
                                                    ) : (
                                                      "Não conforme"
                                                    )
                                                  ) : pergunta.graus?.filter(
                                                      (g: any) =>
                                                        g.value.includes(opt)
                                                    )[0]?.grau === "nao" ? (
                                                    pergunta.respostas.includes(
                                                      opt
                                                    ) ? (
                                                      [
                                                        11, 14, 17, 20, 23, 24,
                                                        25, 26, 27, 28, 30, 31,
                                                        34, 35, 39,
                                                      ].includes(
                                                        pergunta?.ordem
                                                      ) ? (
                                                        <span
                                                          style={{
                                                            color: "#ffa600",
                                                          }}
                                                        >
                                                          <WarningFilled />
                                                          <span
                                                            style={{
                                                              fontSize:
                                                                "0.8rem",
                                                              marginLeft:
                                                                "0.25rem",
                                                            }}
                                                          >
                                                            Ponto de Atenção
                                                          </span>
                                                        </span>
                                                      ) : (
                                                        opt !==
                                                          "Não sei informar" &&
                                                        "Não conforme"
                                                      )
                                                    ) : (
                                                      opt !==
                                                        "Nenhuma Ferramenta" &&
                                                      "Conforme"
                                                    )
                                                  ) : pergunta.graus?.filter(
                                                      (g: any) =>
                                                        g.value.includes(opt)
                                                    )[0]?.grau ===
                                                    "Ponto de Atenção" ? (
                                                    <span
                                                      style={{
                                                        color: "#ffa600",
                                                      }}
                                                    >
                                                      <WarningFilled />
                                                      <span
                                                        style={{
                                                          fontSize: "0.8rem",
                                                          marginLeft: "0.25rem",
                                                        }}
                                                      >
                                                        Ponto de Atenção
                                                      </span>
                                                    </span>
                                                  ) : (
                                                    <>
                                                      {pergunta.graus
                                                        ?.filter((g: any) =>
                                                          g.value.includes(opt)
                                                        )[0]
                                                        ?.grau.replace(
                                                          "meio",
                                                          ""
                                                        )}
                                                      {/* grau caso value = false */}
                                                      {pergunta.graus?.filter(
                                                        (g: any) =>
                                                          !pergunta.respostas.includes(
                                                            opt
                                                          ) &&
                                                          g.checkbox_opt_name ===
                                                            opt
                                                      )[0]?.grau === "nao" && (
                                                        <span
                                                          style={{
                                                            color: "#ffa600",
                                                          }}
                                                        >
                                                          <WarningFilled />
                                                          <span
                                                            style={{
                                                              fontSize:
                                                                "0.8rem",
                                                              marginLeft:
                                                                "0.25rem",
                                                            }}
                                                          >
                                                            Ponto de Atenção
                                                          </span>
                                                        </span>
                                                      )}
                                                      {pergunta.graus?.filter(
                                                        (g: any) =>
                                                          g.checkbox_opt_name ===
                                                          opt
                                                      )[0]?.grau ===
                                                        "Ponto de Atenção" && (
                                                        <span
                                                          style={{
                                                            color: "#ffa600",
                                                          }}
                                                        >
                                                          <WarningFilled />
                                                          <span
                                                            style={{
                                                              fontSize:
                                                                "0.8rem",
                                                              marginLeft:
                                                                "0.25rem",
                                                            }}
                                                          >
                                                            Ponto de Atenção
                                                          </span>
                                                        </span>
                                                      )}{" "}
                                                      {pergunta.graus
                                                        ?.filter((g: any) =>
                                                          g.value.includes(opt)
                                                        )[0]
                                                        ?.grau.includes(
                                                          "Alto Risco"
                                                        ) && (
                                                        <span
                                                          style={{
                                                            color: "#000",
                                                            fontWeight:
                                                              "normal",
                                                            opacity: 0.4,
                                                            fontSize: "0.8rem",
                                                          }}
                                                        >
                                                          (art. 4°, Res. 
                                                          CD/ANPD n°02/2022)
                                                        </span>
                                                      )}
                                                    </>
                                                  )}
                                                </b>
                                              )}
                                          </p>
                                        ))
                                    : pergunta.resposta}
                                </div>
                                {pergunta.norma && (
                                  <>
                                    <h3
                                      style={{
                                        marginBottom: "1rem",
                                        marginTop: "1rem",
                                      }}
                                    >
                                      Boas práticas:
                                    </h3>
                                    <p
                                      style={{
                                        marginLeft: "1rem",
                                        whiteSpace: "pre-wrap",
                                        pageBreakInside: "inherit",
                                      }}
                                    >
                                      {pergunta.norma}
                                    </p>
                                  </>
                                )}
                                {pergunta._id === "660fee5de65c9279bff20fa8" &&
                                  !tratamentoDados && (
                                    <div className="alert">
                                      Com base nas respostas, não é previsto o
                                      tratamento de dados pessoais durante a
                                      execução do contrato entre as partes. Por
                                      isso, indica-se RISCO REDUZIDO de que haja
                                      tratamentos de dados que possam estar em
                                      desconformidade com a LGPD nesta relação
                                      contratual.
                                      <br />
                                      <br />
                                      Observa-se que essa identificação não está
                                      necessariamente relacionada com eventuais
                                      riscos à segurança da informação, à
                                      questões contratuais ou de negócio, que
                                      poderão tornar-se crescentes caso haja
                                      compartilhamento de sistemas, servidores
                                      ou aplicações com o parceiro comercial,
                                      ainda que ele não tenha acesso a dados
                                      pessoais.
                                      <br /> <br />
                                      Caso haja discordância ou dúvida sobre o
                                      uso de dados pessoais, entre em contato
                                      para reavaliarmos ou reenvie o
                                      questionário.
                                      <br /> <br />
                                      Estamos à disposição para esclarecimentos
                                      adicionais.
                                    </div>
                                  )}
                              </div>
                            </>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="page8" style={{ pageBreakAfter: "always" }}>
          <h1 className="title2">
            Próximos
            <br />
            <b>passos:</b>
          </h1>
          <div className="rows">
            <div className="col1"></div>
            <div className="col2">
              <p>
                A <b>continuidade do negócio</b>, do ponto de vista da{" "}
                <b>gestão estratégica de riscos</b> da organização, passa pela
                conformidade com a <b>Lei Geral de Proteção de Dados</b>. Dentre
                outras, a obtenção e evidências de que parceiros comerciais e
                fornecedores estejam em conformidade com a LGPD, é{" "}
                <b>obrigatória</b> para garantir a sua conformidade.
              </p>
              <div className="proximos-passos__containner">
                <p>
                  01<b>Avaliar o resultado</b>
                </p>
                <div className="proximos-passos__hr" />
                <p>
                  02<b>Adequar o plano de ação </b>
                </p>
                <div className="proximos-passos__hr" />
                <p>
                  03<b>Estruturar</b>
                </p>
                <div className="proximos-passos__hr" />
                <p>
                  04<b>Implementar </b>
                </p>
                <div className="proximos-passos__hr" />
                <p>
                  05<b>Monitorar </b>
                </p>
                <div className="proximos-passos__hr" />
              </div>
            </div>
          </div>
          <img
            className="relatorio__seusdados-logo"
            src={LogoSeusdados}
            style={{
              bottom: "0",
              marginTop: "-2rem",
              // paddingBottom: "2rem",
            }}
            alt="logo seusdados"
          />
        </div>
        <div className="page9" style={{ pageBreakAfter: "always" }}>
          <h1 className="title2">Obrigado</h1>
          <div className="rows">
            <div className="col1"></div>
            <div className="col2">
              <p>
                O time da <b>Seusdados Consultoria</b> está a sua disposição
                para esclarecer dúvidas e também ajudar, não deixe de nos
                chamar.
              </p>
              <div className="address-info">
                <p>
                  <CompassOutlined className="address-info--icon" />
                  <span>Rua Afonso Arinos, 24, Vila Nova Espéria,</span>
                  <br />
                  <span style={{ marginLeft: "5.6rem" }}>
                    Jundiaí-SP, 13.211-030
                  </span>
                </p>
                <p>
                  <PhoneOutlined className="address-info--icon" />
                  <span>+55 11 4040 5552</span>
                </p>
                <p>
                  <MailOutlined className="address-info--icon" />
                  <span>marcelo@seusdados.com</span>
                </p>
                <p>
                  <SearchOutlined className="address-info--icon" />
                  <span>
                    <a href="www.seusdados.com">www.seusdados.com</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <img
            className="relatorio__seusdados-logo"
            src={LogoSeusdados}
            style={{
              marginTop: "12rem",
              // paddingBottom: "2rem",
            }}
            alt="logo seusdados"
          />
        </div>
      </div>
    ) : (
      <></>
    );

  const tabItems = [
    {
      key: "1",
      label: `Análise`,
      children: <Analise questId={params.qid} />,
    },
    // {
    //   key: "2",
    //   label: `Plano de ação`,
    //   children: <PlanoAcao activeCap={params.fornecedorid} questId={params.clienteid} />,
    // },
  ];

  return (
    <div className="editAnalise">
      {!userInfo?.name && (
        <>
          <h1 style={{ marginBottom: "1rem" }}>Análise das respostas</h1>
          <hr />
        </>
      )}
      {userInfo?.name && (
        <>
          <BackHeader title={"Análise"} prev />
          <hr />
        </>
      )}
      {userInfo?.name && allRespostas[0] ? (
        <Tabs
          tabBarExtraContent={{
            right:
              fornecedores?.filter(
                (f: any) => f._id.toString() === params.fornecedorid
              )[0] &&
              !isNaN(porcentagemConforme) &&
              tratamentoDados !== null &&
              porcentagemConforme > 0 ? (
                <PdfDownload title={"Imprimir Análise"} content={content} />
              ) : (
                <></>
              ),
          }}
          defaultActiveKey="1"
          items={tabItems}
          className="editAnalise__tabs"
        />
      ) : (
        <Analise questId={params.qid} />
      )}
    </div>
  );
};

export default AnaliseFornecedor;
