import React, { useEffect, useState } from "react";
import "./styles.css";

import { Checkbox, Popover, Tag } from "antd";
import { InfoCircleTwoTone, WarningFilled } from "@ant-design/icons";
import { AnaliseRespostasTypes } from "../../../../../../../@types/analise";
import { GlobalContextType } from "../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../context/globalContext";
import { useParams } from "react-router-dom";
import api from "../../../../../../../services/api";
interface Response {
  questao_id: string;
  resposta: string[];
}
interface DependenciaType {
  type: string;
  value: string;
  depende: string;
}
interface Questao {
  _id: string;
  questao: string;
  opcoes: string[];
  tipo: string;
  formate?: string;
  dependencia?: DependenciaType[];
  ordem: number;
  required?: boolean;
  description?: string;
}
const Respostas: React.FC<AnaliseRespostasTypes> = ({ activeCap, questId }) => {
  const { grauRiscos, windowWidth } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const params = useParams();

  const [allRespostas, setAllRespostas] = useState<any>([]);

  const [mergedData, setMergedData] = useState<
    { questao: string; ordem: number; respostas: string[] }[]
  >([]);

  useEffect(() => {
    async function fetchData() {
      if (grauRiscos[0]) {
        try {
          const response = await api.get(
            `/respostasforn/${params.questionarioid}`
          );

          const questoesResponse = await api.get("/questoes");
          const questoes: Questao[] = questoesResponse.data;

          const mergedDataArray = response.data.respostas.map(
            (resposta: Response) => {
              const questao = questoes.find(
                (q: Questao) => q._id === resposta.questao_id
              );

              return {
                ...questao,
                respostas: resposta.resposta,
                graus: grauRiscos.filter(
                  (g: any) =>
                    g.questao_id.toString() === questao?._id.toString()
                ),
              };
            }
          );

          const sortedMergedData = mergedDataArray.sort(
            (a: any, b: any) => a.ordem - b.ordem
          );

          let allRespData: any = [];

          sortedMergedData
            .filter((r: any) => r._id)
            .forEach((questao: any) => {
              if (questao.respostas && questao.respostas[0]) {
                questao.respostas.map((item: any) => {
                  if (
                    allRespData.filter(
                      (item: any) =>
                        item._id.toString() === questao._id.toString()
                    ).length !== 0
                  ) {
                    return;
                  }
                  return allRespData.push({
                    ...questao,
                    resposta: item,
                    grau: grauRiscos
                      .filter(
                        (gr: any) =>
                          gr.questao_id.toString() === questao._id.toString()
                      )
                      .filter((gr: any) => gr.value.includes(item))[0]?.grau,
                  });
                });
              }
            });

          setAllRespostas(allRespData);
          setMergedData(sortedMergedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
    fetchData();
  }, [params.questionarioid, grauRiscos]);

  function encontrarIndicePorValor(valorProcurado: any) {
    // Utilize a função map para percorrer o array
    // A função map retorna um novo array com os índices dos objetos que correspondem ao valor procurado
    const indicesEncontrados = allRespostas
      .sort((a: any, b: any) => a.ordem - b.ordem)
      .map((objeto: any, indice: number) => {
        if (objeto === valorProcurado) {
          return indice + 1;
        }
        return null; // Retorna null para os objetos que não correspondem ao valor procurado
      });

    // Filtra os índices válidos (diferentes de null)
    const indicesValidos = indicesEncontrados.filter(
      (indice: number) => indice !== null
    );

    return indicesValidos;
  }

  return (
    <div className="analise__respostas" style={{ overflow: "auto" }}>
      {windowWidth > 800 && (
        <>
          <div className="respostas__headerCap">
            <h2>{activeCap}</h2>
          </div>
          <hr className="hrAnalise" />
        </>
      )}

      <div className="respostas__containner">
        {windowWidth > 800 &&
          !allRespostas?.filter((r: any) => r.categoria === activeCap)[0] && (
            <h3>Sem respostas</h3>
          )}
        {allRespostas && (
          <div className="respostas__pergunta">
            {allRespostas
              ?.filter((r: any) => r.categoria === activeCap)
              .map((pergunta: any, index: number) => {
                // const currentResposta = currentAnalise.respostas.filter(
                //   (r: any) => r.pergunta_id === pergunta._id
                // )[0];

                // if (currentResposta?.cap_id !== activeCap._id) {
                //   return null;
                // }
                return (
                  <div key={pergunta + "analiseResp"}>
                    {/* {windowWidth <= 800 && index === 0 && (
                      <>
                        <div className="respostas__headerCap">
                          <h2 style={{ marginTop: "2rem !important" }}>
                            {activeCap}
                          </h2>
                        </div>
                        <hr className="hrAnalise" />
                      </>
                    )} */}

                    <div
                      className="respostas__pergunta--label"
                      style={{ fontSize: 16 }}
                    >
                      <b>{encontrarIndicePorValor(pergunta)}. </b>
                      {pergunta.questao}{" "}
                      {pergunta.norma && (
                        <Popover
                          title="Norma"
                          trigger={"click"}
                          content={
                            <p
                              style={{
                                maxHeight: 300,
                                overflowX: "auto",
                                padding: "1rem",
                              }}
                            >
                              {pergunta.norma}
                            </p>
                          }
                          overlayStyle={{
                            maxWidth: "600px",
                            whiteSpace: "pre-line",
                            maxHeight: "300px",
                          }}
                        >
                          <InfoCircleTwoTone className="info" />
                        </Popover>
                      )}
                      {!pergunta.graus[0] && (
                        <Tag
                          color={"blue"}
                          style={{
                            marginLeft: "0.5rem",
                            fontSize: "0.8rem",
                            color: "#1677ff",
                            top: "-0.15rem",
                          }}
                        >
                          Conhecimento de Processo
                        </Tag>
                      )}
                    </div>

                    <div style={{ marginLeft: "1rem" }}>
                      {pergunta.tipo !== "input"
                        ? pergunta.opcoes
                            .filter((opt: string) =>
                              pergunta.ordem === 8
                                ? allRespostas
                                    ?.find((r: any) => r.ordem === 7)
                                    ?.respostas?.includes(opt)
                                : true
                            )
                            ?.map((opt: string) => (
                              <p>
                                <Checkbox
                                  checked={pergunta.respostas.includes(opt)}
                                  style={{ marginRight: "0.5rem" }}
                                />
                                {opt}{" "}
                                {pergunta.graus[0] &&
                                  pergunta.respostas.includes(opt) && (
                                    <b
                                      className={
                                        pergunta.graus?.filter((g: any) =>
                                          g.value.includes(opt)
                                        )[0]?.grau === "sim"
                                          ? pergunta.respostas.includes(opt)
                                            ? "respostas__pergunta--label__conforme"
                                            : "respostas__pergunta--label__inconforme"
                                          : pergunta.graus?.filter((g: any) =>
                                              g.value.includes(opt)
                                            )[0]?.grau === "nao"
                                          ? pergunta.respostas.includes(opt)
                                            ? "respostas__pergunta--label__inconforme"
                                            : "respostas__pergunta--label__conforme"
                                          : "respostas__pergunta--label__blue"
                                      }
                                    >
                                      {pergunta.graus?.filter((g: any) =>
                                        g.value.includes(opt)
                                      )[0]?.grau === "sim" ? (
                                        pergunta.respostas.includes(opt) ? (
                                          "Conforme"
                                        ) : [
                                            11, 14, 17, 20, 21, 23, 24, 25, 26,
                                            27, 28, 30, 31, 34, 35, 39,
                                          ].includes(pergunta?.ordem) ? (
                                          <span style={{ color: "#ffa600" }}>
                                            <WarningFilled />
                                            <span
                                              style={{
                                                fontSize: "0.8rem",
                                                marginLeft: "0.25rem",
                                              }}
                                            >
                                              Ponto de Atenção
                                            </span>
                                          </span>
                                        ) : (
                                          "Não conforme"
                                        )
                                      ) : pergunta.graus?.filter((g: any) =>
                                          g.value.includes(opt)
                                        )[0]?.grau === "nao" ? (
                                        pergunta.respostas.includes(opt) ? (
                                          [
                                            11, 20, 18, 21, 23, 24, 25, 26, 27,
                                            28, 30, 31, 34, 35, 39,
                                          ].includes(pergunta?.ordem) ? (
                                            <span style={{ color: "#ffa600" }}>
                                              <WarningFilled />
                                              <span
                                                style={{
                                                  fontSize: "0.8rem",
                                                  marginLeft: "0.25rem",
                                                }}
                                              >
                                                Ponto de Atenção
                                              </span>
                                            </span>
                                          ) : (
                                            "Não conforme"
                                          )
                                        ) : (
                                          "Conforme"
                                        )
                                      ) : pergunta.graus?.filter((g: any) =>
                                          g.value.includes(opt)
                                        )[0]?.grau === "Ponto de Atenção" ? (
                                        <span style={{ color: "#ffa600" }}>
                                          <WarningFilled />
                                          <span
                                            style={{
                                              fontSize: "0.8rem",
                                              marginLeft: "0.25rem",
                                            }}
                                          >
                                            Ponto de Atenção
                                          </span>
                                        </span>
                                      ) : (
                                        <>
                                          {pergunta.graus
                                            ?.filter((g: any) =>
                                              g.value.includes(opt)
                                            )[0]
                                            ?.grau.replace("meio", "")}{" "}
                                          {pergunta.graus
                                            ?.filter((g: any) =>
                                              g.value.includes(opt)
                                            )[0]
                                            ?.grau.includes("Alto Risco") && (
                                            <span
                                              style={{
                                                color: "#000",
                                                fontWeight: "normal",
                                                opacity: 0.4,
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              (art. 4°, Res.  CD/ANPD n°02/2022)
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </b>
                                  )}
                                {[8, 12, 28].includes(pergunta.ordem) &&
                                  pergunta.graus[0] && (
                                    <b
                                      className={
                                        pergunta.graus?.filter((g: any) =>
                                          g.value.includes(opt)
                                        )[0]?.grau === "sim"
                                          ? pergunta.respostas.includes(opt)
                                            ? "respostas__pergunta--label__conforme"
                                            : "respostas__pergunta--label__inconforme"
                                          : pergunta.graus?.filter((g: any) =>
                                              g.value.includes(opt)
                                            )[0]?.grau === "nao"
                                          ? pergunta.respostas.includes(opt)
                                            ? "respostas__pergunta--label__inconforme"
                                            : "respostas__pergunta--label__conforme"
                                          : "respostas__pergunta--label__blue"
                                      }
                                    >
                                      {pergunta.graus?.filter((g: any) =>
                                        g.value.includes(opt)
                                      )[0]?.grau === "sim" ? (
                                        pergunta.respostas.includes(opt) ? (
                                          pergunta.ordem !== 8 ? (
                                            "Conforme"
                                          ) : (
                                            ""
                                          )
                                        ) : [
                                            11, 14, 17, 20, 23, 24, 25, 26, 27,
                                            28, 30, 31, 34, 35, 39,
                                          ].includes(pergunta?.ordem) ? (
                                          <span style={{ color: "#ffa600" }}>
                                            <WarningFilled />
                                            <span
                                              style={{
                                                fontSize: "0.8rem",
                                                marginLeft: "0.25rem",
                                              }}
                                            >
                                              Ponto de Atenção
                                            </span>
                                          </span>
                                        ) : (
                                          "Não conforme"
                                        )
                                      ) : pergunta.graus?.filter((g: any) =>
                                          g.value.includes(opt)
                                        )[0]?.grau === "nao" ? (
                                        pergunta.respostas.includes(opt) ? (
                                          [
                                            11, 14, 17, 20, 23, 24, 25, 26, 27,
                                            28, 30, 31, 34, 35, 39,
                                          ].includes(pergunta?.ordem) ? (
                                            <span style={{ color: "#ffa600" }}>
                                              <WarningFilled />
                                              <span
                                                style={{
                                                  fontSize: "0.8rem",
                                                  marginLeft: "0.25rem",
                                                }}
                                              >
                                                Ponto de Atenção
                                              </span>
                                            </span>
                                          ) : (
                                            opt !== "Não sei informar" &&
                                            "Não conforme"
                                          )
                                        ) : (
                                          opt !== "Nenhuma Ferramenta" &&
                                          opt !== "Não sei informar" &&
                                          "Conforme"
                                        )
                                      ) : pergunta.graus?.filter((g: any) =>
                                          g.value.includes(opt)
                                        )[0]?.grau === "Ponto de Atenção" ? (
                                        <span style={{ color: "#ffa600" }}>
                                          <WarningFilled />
                                          <span
                                            style={{
                                              fontSize: "0.8rem",
                                              marginLeft: "0.25rem",
                                            }}
                                          >
                                            Ponto de Atenção
                                          </span>
                                        </span>
                                      ) : (
                                        <>
                                          {pergunta.graus
                                            ?.filter((g: any) =>
                                              g.value.includes(opt)
                                            )[0]
                                            ?.grau.replace("meio", "")}
                                          {/* grau caso value = false */}
                                          {pergunta.graus?.filter(
                                            (g: any) =>
                                              !pergunta.respostas.includes(
                                                opt
                                              ) && g.checkbox_opt_name === opt
                                          )[0]?.grau === "nao" && (
                                            <span style={{ color: "#ffa600" }}>
                                              <WarningFilled />
                                              <span
                                                style={{
                                                  fontSize: "0.8rem",
                                                  marginLeft: "0.25rem",
                                                }}
                                              >
                                                Ponto de Atenção
                                              </span>
                                            </span>
                                          )}
                                          {pergunta.graus?.filter(
                                            (g: any) =>
                                              g.checkbox_opt_name === opt
                                          )[0]?.grau === "Ponto de Atenção" && (
                                            <span style={{ color: "#ffa600" }}>
                                              <WarningFilled />
                                              <span
                                                style={{
                                                  fontSize: "0.8rem",
                                                  marginLeft: "0.25rem",
                                                }}
                                              >
                                                Ponto de Atenção
                                              </span>
                                            </span>
                                          )}{" "}
                                          {pergunta.graus
                                            ?.filter((g: any) =>
                                              g.value.includes(opt)
                                            )[0]
                                            ?.grau.includes("Alto Risco") && (
                                            <span
                                              style={{
                                                color: "#000",
                                                fontWeight: "normal",
                                                opacity: 0.4,
                                                fontSize: "0.8rem",
                                              }}
                                            >
                                              (art. 4°, Res.  CD/ANPD n°02/2022)
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </b>
                                  )}
                              </p>
                            ))
                        : pergunta.resposta}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Respostas;
