import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  Form,
  Input,
  List,
  Radio,
  Timeline,
  message,
} from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SendOutlined,
} from "@ant-design/icons";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { GlobalContextType } from "../../@types/global";
import { GlobalContext } from "../../context/globalContext";
import api from "../../services/api";
import moment from "moment";
import Modal from "antd/lib/modal/Modal";
import { Space } from "antd/lib";

const { TextArea } = Input;

interface CommentsProps {
  task_id: string;
  tipo?: string;
}

interface ContentDisplayProps {
  content: string;
}

const Comments: React.FC<CommentsProps> = ({ task_id, tipo }) => {
  const {
    userInfo,
    moduloTask,
    atualizaStatusAcaoFornecedor,
    fornecedores,
    clientes,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [comment, setComment] = useState<string>("");
  const [verMais, setVerMais] = useState<number | null>(null);
  const [currentTask, setCurrentTask] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [statusFornecedor, setStatusFornecedor] = useState("");
  const [justificativaFornecedor, setJustificativaFornecedor] = useState("");
  const [outrosJuntificativa, setOutrosJuntificativa] = useState("");

  useEffect(() => {
    setCurrentTask(moduloTask?.tasks?.find((t) => t._id === task_id));
  }, [task_id]);

  const options = [
    {
      label: "Continuar contrato",
      value: "continuar",
      icon: <CheckOutlined />,
    },
    {
      label: "Descontinuar contrato",
      value: "descontinuar",
      icon: <CloseOutlined />,
    },
    {
      label: "Aditar contrato",
      value: "continuarcontrato",
      icon: <EditOutlined />,
    },
  ];

  const optionsJustificativa = [
    {
      label: "Fornecedor estratégico / ausência de outros fornecedores",
      value: "ausenciafornecedores",
    },
    {
      label: "Ausência de tratamento de dados relevante",
      value: "ausenciadados",
    },
    {
      label: "Decisão estatégica ou de negócio da alta gestão",
      value: "decisaoestrategica",
    },
    // {
    //   label: "Outros",
    //   value: "outros",
    // },
  ];

  const [form] = Form.useForm();

  const ContentDisplay: React.FC<ContentDisplayProps> = ({ content }) => {
    // Função para verificar se o conteúdo é HTML
    const isHTML = (str: string): boolean => {
      const doc = new DOMParser().parseFromString(str, "text/html");
      return Array.from(doc.body.childNodes).some(
        (node) => node.nodeType === 1
      );
    };

    // Renderiza o conteúdo em uma div se for HTML, senão usa o TextArea
    const renderContent = () => {
      if (isHTML(content)) {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            style={{
              border: "none",
              overflow: "hidden",
              whiteSpace: "pre-wrap",
            }}
          />
        );
      } else {
        return (
          <TextArea
            autoSize={{ minRows: 3, maxRows: 100 }}
            value={content}
            readOnly
            style={{ border: "none", overflow: "hidden" }}
          />
        );
      }
    };

    return renderContent();
  };

  const handleSend = async (values: any) => {
    try {
      // Substitua a URL pela sua rota de backend para postar um comentário
      await api.post("/comments", {
        task_id: moduloTask.selectedTask?._id || task_id,
        comment: comment,
        user_id: userInfo._id,
      });

      message.success("Comentário enviado com sucesso!");
      setComment(""); // Limpar o formulário após o envio
      moduloTask.getComments(moduloTask.selectedTask?._id || task_id);
      setTimeout(function () {
        moduloTask.getComments(moduloTask.selectedTask?._id || task_id);
      }, 2200);
    } catch (error) {
      console.error("Erro ao enviar o comentário", error);
      message.error("Erro ao enviar o comentário. Tente novamente mais tarde.");
    }
  };

  useEffect(() => {
    ((moduloTask.selectedTask && moduloTask.selectedTask._id) || task_id) &&
      moduloTask.getComments(moduloTask?.selectedTask?._id || task_id);
  }, [moduloTask.selectedTask, task_id]);

  const handleVerMais = (index: number) => {
    verMais === index ? setVerMais(null) : setVerMais(index);
  };

  const handleRadioChange = (e: any) => {
    let opcaoSelecionada: string;
    if (e.target) {
      opcaoSelecionada = e.target.value;
    } else {
      opcaoSelecionada = e;
    }

    let textoFonecedor = `Diante do resultado da análise do fornecedor ${
      currentTask.modelo_info?.nome
    }, a empresa ${
      clientes?.find((c: any) => c._id === userInfo?.cliente_id)?.razao_social
    } pretende `;

    const opcoes: any = {
      a: "Executarei a recomendação sugerida para alcançar uma conformidade aprimorada.",
      b: "Preciso de investimento para abordar a vulnerabilidade.",
      c: "Estou disposto(a) a assumir o risco associado à vulnerabilidade apresentada.",
      d: "Nenhuma das opções anteriores se adequa à minha situação atual.",
      continuar: textoFonecedor + "continuar com esta parceria.",
      descontinuar: textoFonecedor + "cescontinuar esta parceria.",
      continuarcontrato:
        textoFonecedor + "criar um aditivo ao contrado do parceiro.",
      ausenciafornecedores:
        "Justificativa:\nFornecedor estratégico / ausência de outros fornecedores",
      ausenciadados:
        "Justificativa:\nAusência de tratamento de dados relevante",
      decisaoestrategica:
        "Justificativa:\nDecisão estatégica ou de negócio da alta gestão",
      // outros: "Justificativa:\nDecisão estatégica ou de negócio da alta gestão",
    };

    // se for  ausenciafornecedores, ausenciadados ou decisaoestrategica,
    if (
      ["ausenciafornecedores", "ausenciadados", "decisaoestrategica"].includes(
        opcaoSelecionada
      )
    ) {
      // Verificar se ja existe um dos comentarios
      setComment(
        (prev: string) =>
          prev
            .replace(
              "\n\nJustificativa:\nFornecedor estratégico / ausência de outros fornecedores",
              ""
            )
            .replace(
              "\n\nJustificativa:\nAusência de tratamento de dados relevante",
              ""
            )
            .replace(
              "\n\nJustificativa:\nDecisão estatégica ou de negócio da alta gestão",
              ""
            ) + `\n\n${opcoes[opcaoSelecionada]}`
      );
    } else {
      setComment(opcoes[opcaoSelecionada] || "");
      // form.setFieldsValue({ description: opcoes[opcaoSelecionada] || "" });
    }
  };

  useEffect(() => {
    form.setFieldsValue({ description: comment });
  }, [comment]);

  const handleShowModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };

  // Array de 10 cores predefinidas em formato hexadecimal
  const colors = [
    "#FF9C80",
    "#80FF9C",
    "#809CFF",
    "#FF80FF",
    "#FF80C3",
    "#80FFF0",
    "#FFC080",
    "#C080FF",
    "#FF80A6",
    "#80FFC0",
  ];

  // Função que retorna uma cor aleatória do array de cores
  function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  return (
    <div className="comments">
      <Form className="planoAcao__form" onFinish={handleSend} form={form}>
        {moduloTask.selectedTask?.tipo !== "pauta" &&
          tipo !== "pauta" &&
          (currentTask &&
          currentTask.tipo === "subfornecedores" &&
          !moduloTask.comments.filter(
            (c: any) =>
              c.task_id.toString() ===
                moduloTask.selectedTask?._id.toString() || c.task_id === task_id
          )[0] ? (
            <>
              <Radio.Group
                optionType={"button"}
                onChange={(e) => {
                  setStatusFornecedor(e.target.value);
                  setShowModal(true);
                }}
                value={statusFornecedor}
              >
                {options.map((option) => (
                  <Radio
                    key={option.value}
                    style={{ marginBottom: "1rem" }}
                    value={option.value}
                  >
                    {option.icon} {option.label}
                  </Radio>
                ))}
              </Radio.Group>
              {statusFornecedor && (
                <>
                  <br />
                  <h4 style={{ marginBottom: "0.5rem" }}>
                    Justificativas da decisão
                  </h4>
                  <Radio.Group
                    onChange={(e) => {
                      setJustificativaFornecedor(e.target.value);
                      handleRadioChange(e);
                    }}
                    value={justificativaFornecedor}
                  >
                    <Space direction="vertical">
                      {optionsJustificativa.map((option) => (
                        <Radio key={option.value} value={option.value}>
                          {option.value === "outros" ? (
                            <div style={{ display: "flex" }}>
                              Outros:{" "}
                              <TextArea
                                onChange={(v) =>
                                  setOutrosJuntificativa(v.target.value)
                                }
                                placeholder="Descreva..."
                                size="small"
                                autoSize={{ minRows: 3, maxRows: 20 }}
                                style={{
                                  marginLeft: "0.5rem",
                                  position: "relative",
                                }}
                              />
                            </div>
                          ) : (
                            option.label
                          )}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </>
              )}

              <p
                style={{
                  marginBottom: "1rem",
                  marginTop: statusFornecedor ? "1rem" : 0,
                }}
              >
                *Descreva abaixo as ações pretendidas para minimizar os riscos e
                impactos da decisão:
              </p>
            </>
          ) : (
            // <Radio.Group
            //   onChange={handleRadioChange}
            //   style={{ marginBottom: "1rem" }}
            // >
            //   <Radio.Button value="a">Executar a recomendação</Radio.Button>
            //   <Radio.Button value="b">Necessito de investimento</Radio.Button>
            //   <Radio.Button value="c">Assumir o risco</Radio.Button>
            //   <Radio.Button value="d">Nenhuma das anteriores</Radio.Button>
            // </Radio.Group>
            <></>
          ))}
        <Form.Item name={"description"}>
          <TextArea
            required
            rows={8}
            value={comment}
            onChange={(v: any) => setComment(v.target.value)}
            placeholder="Escreva..."
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="planoAcao__btn"
            type="primary"
            htmlType="submit"
            icon={<SendOutlined />}
          >
            {tipo === "pauta" ? "Adicionar deliberação" : "Enviar"}
          </Button>
        </Form.Item>
      </Form>
      {moduloTask.comments?.filter(
        (c: any) =>
          c.task_id.toString() === moduloTask.selectedTask?._id.toString() ||
          c.task_id === task_id
      )?.[0] && (
        <List
          itemLayout="horizontal"
          dataSource={moduloTask.comments
            .filter(
              (c: any) =>
                c.task_id.toString() ===
                  moduloTask.selectedTask?._id.toString() ||
                c.task_id === task_id
            )
            .sort(
              (a: any, b: any) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )}
          renderItem={(item: any, index) => {
            return (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      src={`https://ui-avatars.com/api/?name=${item.user_name.replaceAll(
                        " ",
                        "+"
                      )}&background=ebebeb`}
                    />
                  }
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {item.user_name}
                      <div
                        style={{
                          fontSize: 10,
                          opacity: 0.5,
                          marginLeft: 8,
                          marginTop: 2,
                        }}
                      >
                        {moment(new Date(item.created_at)).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </div>
                    </div>
                  }
                  description={
                    item.comment?.length >= 700 ? (
                      // verMais === index && (
                      <>
                        <Button
                          type="link"
                          key={"ver-mais-" + index}
                          style={{
                            marginLeft: "-1rem",
                          }}
                          onClick={() => handleVerMais(index)}
                        >
                          {verMais === index
                            ? "Ver menos"
                            : "Ver mensagem completa"}
                        </Button>
                        {verMais === index && (
                          <ContentDisplay content={item.comment} />
                        )}
                      </>
                    ) : (
                      <ContentDisplay content={item.comment} />
                    )
                  }
                />
              </List.Item>
            );
          }}
        />
      )}
      <Modal
        title={
          statusFornecedor === "continuar"
            ? "Continuar contrato"
            : statusFornecedor === "descontinuar"
            ? "Descontinuar contrato"
            : "Aditar contrato"
        }
        open={showModal}
        footer={[
          <Button
            type="default"
            form="form-pergunta"
            onClick={() => {
              setShowModal(false);
              setStatusFornecedor("");
            }}
          >
            Cancelar
          </Button>,
          <Button
            onClick={() => {
              atualizaStatusAcaoFornecedor(
                statusFornecedor,
                currentTask.modelo_referencia_id
              );
              handleRadioChange(statusFornecedor);
              setShowModal(false);
            }}
            type="primary"
          >
            Prosseguir
          </Button>,
        ]}
        width={600}
        onCancel={() => {
          handleShowModal();
          setStatusFornecedor("");
        }}
      >
        {statusFornecedor === "continuar" ? (
          <p style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            Atenção! Antes de prosseguir com esta parceria, é importante
            recordar que algumas inconformidades e possíveis riscos foram
            identificados nas Respostas do Questionário. Recomendamos que seja
            feita uma análise cuidadosa internamente sobre como essas
            inconformidades podem afetar e gerar riscos para a sua empresa antes
            de tomar uma decisão final sobre a continuidade do negócio
          </p>
        ) : statusFornecedor === "descontinuar" ? (
          <p>Você deseja descontinuar fornecedor?</p>
        ) : (
          <p>
            Para resguardar juridicamente a sua empresa, sugere-se que seja
            elaborado um aditivo contratual para que o seu Parceiro Comercial
            tenha como sua obrigação a adequação à LGPD, especialmente em
            relação às inconformidades encontradas nas Respostas do
            Questionário.
            <br />
            <br />
            Para criar um aditivo detalhado e específico, recomenda-se que seja
            aberto um novo chamado na plataforma MeuDPO
          </p>
        )}
      </Modal>
    </div>
  );
};

export default Comments;
