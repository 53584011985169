import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles.css";
import {
  Button,
  Form,
  Input,
  Select,
  Tooltip,
  Modal,
  Table,
  Col,
  Row,
  Collapse,
} from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  OrderedListOutlined,
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import Search from "antd/es/input/Search";
import TextArea from "antd/es/input/TextArea";
import SaveBtn from "../../../../components/SaveBtn/SaveBtn";

type EditTemplateProps = {
  mode?: "template" | "editar" | "delegar";
  versaoId?: string | null;
};
interface LiderProcessoProps {
  processo_id: string;
  users: string[];
}
const { Option } = Select;

const EditTemplate: React.FC<EditTemplateProps> = ({ mode, versaoId }) => {
  const {
    moduloProcessos,
    moduloMapeamento,
    userInfo,
    currentCliente,
    usuarios,
    delegarQuestionario,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenPerg, setIsModalOpenPerg] = useState<boolean>(false);
  const [selectedQuest, setSelectedQuest] = useState<any>();
  const [currentAreas, setCurrentAreas] = useState<any>([]);
  const [currentQuestionarios, setCurrentQuestionarios] =
    React.useState<any>(null);
  const onSearchCap = (value: string) => setSearchCap(value);
  const onSearchPerg = (value: string) => setSearchPerg(value);
  const [searchCap, setSearchCap] = React.useState<string>("");
  const [searchPerg, setSearchPerg] = React.useState<string>("");
  const [questName, setQuestName] = React.useState<string>("");
  const [liderAreas, setLiderAreas] = useState<any>([]);
  const [liderProcessos, setLiderProcessos] = useState<
    LiderProcessoProps[] | null
  >(null);
  const [selected, setSelected] = React.useState<any>();
  const params = useParams();

  useEffect(() => {
    moduloMapeamento.templates &&
      params.templateid &&
      setCurrentQuestionarios(
        moduloMapeamento.templates?.filter(
          (q: any) => q._id === params.templateid
        )[0]
      );
  }, [params.templateid, moduloMapeamento.templates]);

  useEffect(() => {
    !currentQuestionarios &&
      moduloMapeamento.questionarios &&
      params.qid &&
      setCurrentQuestionarios(
        moduloMapeamento.questionarios?.filter(
          (q: any) => q._id === params.qid
        )[0]
      );
  }, [params.qid, moduloMapeamento.questionarios]);

  useEffect(() => {
    if (currentQuestionarios) {
      form.setFieldsValue({
        name: currentQuestionarios.nome,
      });

      setCurrentAreas(currentQuestionarios.areas);
    }
  }, [currentQuestionarios]);

  const onFinish = () => {
    switch (mode) {
      case "delegar":
        delegarQuestionario({
          versao_id: versaoId,
          ...currentQuestionarios,
          lider_areas: liderAreas,
          lider_processos: liderProcessos,
        });

        break;

      default:
        moduloMapeamento.saveQuestionario(null, {
          ...currentQuestionarios,
          lider_areas: liderAreas,
          lider_processos: liderProcessos,
        });
        break;
    }
  };

  const columnsPerg = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
  ];

  const showModalPerg = () => {
    setIsModalOpenPerg(true);
  };

  const showModalCap = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpenPerg(false);
    setIsModalOpen(false);
  };

  const handleChangeArea = (v: any) => {
    setCurrentAreas((prev: any) => [
      ...prev.filter((value: any) => value !== v),
      v,
    ]);
    setSelected(null);
  };

  const removeArea = (v: any) => {
    setCurrentAreas((prev: any) => [
      ...prev.filter((value: any) => value !== v),
    ]);
    setSelected(null);
  };

  const addCap = (q: any, area: any) => {
    setSelectedQuest({ questionario: q, area: area });
    showModalCap();
  };

  const addPerg = (q: any, area: any, cap: any) => {
    setSelectedQuest({ questionario: q, area: area, capitulo: cap });
    showModalPerg();
  };

  const columnsCap = [
    {
      title: "Nome",
      dataIndex: "description",
    },
  ];

  const gerarQuestionario = () => {
    const currentCapitulos = moduloProcessos.capitulos?.filter((cap: any) =>
      cap.tags.some((tag: any) => currentAreas.includes(tag))
    );

    const capituloIds = currentCapitulos?.map((capitulo: any) => capitulo._id);

    const capituloPerguntasRelacionadas =
      moduloProcessos.capituloPerguntas?.filter((relacao: any) =>
        capituloIds?.includes(relacao.capitulo_id)
      );

    setCurrentQuestionarios({
      _id: currentQuestionarios ? currentQuestionarios._id || null : null,
      nome: questName || currentQuestionarios.nome,
      areas: currentAreas,
      capitulos: currentCapitulos,
      perguntasRelacao: capituloPerguntasRelacionadas,
    });
  };

  const removerPergunta = (pergunta: any) => {
    if (pergunta._id) {
      setCurrentQuestionarios((prev: any) => ({
        ...prev,
        perguntasRelacao: [
          ...prev.perguntasRelacao.filter(
            (p: any) => p.pergunta_id !== pergunta.pergunta_id
          ),
          {
            _id: pergunta._id,
            pergunta_id: pergunta.pergunta_id,
            capitulo_id: pergunta.capitulo_id,
            inativo: true,
          },
        ],
      }));
    } else {
      // caso seja pergunta adicionada a parte, sem pergId
      setCurrentQuestionarios((prev: any) => ({
        ...prev,
        perguntasRelacao: prev.perguntasRelacao.filter(
          (p: any) => p.pergunta_id !== pergunta.pergunta_id
        ),
      }));
    }
  };

  const removerCap = (cap: any) => {
    setCurrentQuestionarios((prev: any) => ({
      ...prev,
      capitulos: [
        ...prev.capitulos.filter((c: any) => c._id !== cap._id),
        {
          ...cap,
          inativo: true,
        },
      ],
    }));
  };

  const removerArea = (area_id: string) => {
    setCurrentQuestionarios((prev: any) => ({
      ...prev,
      areas: prev.areas.filter((a: any) => a !== area_id),
    }));
  };

  return (
    <div className="">
      {mode !== "delegar" && (
        <BackHeader
          title={
            params.templateid || params.qid
              ? "Editar Questionário"
              : "Novo Questionário"
          }
          prev
        />
      )}

      <Form
        form={form}
        layout="vertical"
        name="edit-clientes-form"
        onFinish={onFinish}
      >
        {userInfo?.grupos?.find(
          (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
        ) &&
          mode !== "delegar" && (
            <>
              <Row>
                <Col span={11}>
                  <Form.Item name={"name"} label="Nome" required>
                    <Input
                      placeholder="Nome do questionário"
                      required
                      onChange={(v: any) => setQuestName(v.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={2} />
                <Col span={11}>
                  <Form.Item label="Áreas">
                    <Form.Item>
                      <Select
                        options={moduloProcessos.areas}
                        placeholder="Adicionar"
                        value={selected}
                        fieldNames={{ label: "description", value: "_id" }}
                        onChange={(v: any) => handleChangeArea(v)}
                      />
                    </Form.Item>
                    {currentAreas[0] &&
                      currentAreas.map((field: any) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "0.5rem",
                          }}
                        >
                          <MinusCircleOutlined
                            onClick={() => removeArea(field)}
                            style={{ marginRight: "2rem", color: "red" }}
                          />
                          <p
                          // style={{
                          //   color: "rgb(59, 145, 252)",
                          // }}
                          >
                            {
                              moduloProcessos.areas?.filter(
                                (a: any) => a._id === field
                              )[0].description
                            }
                          </p>
                        </div>
                      ))}
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name={"obs"} label="Observações">
                <TextArea rows={6} placeholder="Digite observações..." />
              </Form.Item>

              {currentAreas[0] && (currentQuestionarios?.name || questName) && (
                <>
                  <hr />

                  <Form.Item>
                    <Button
                      type="primary"
                      icon={
                        currentQuestionarios ? (
                          <ReloadOutlined />
                        ) : (
                          <OrderedListOutlined />
                        )
                      }
                      onClick={() => gerarQuestionario()}
                    >
                      {currentQuestionarios
                        ? "Gerar novamente"
                        : "Gerar Questionário"}
                    </Button>
                  </Form.Item>
                </>
              )}
            </>
          )}

        {currentQuestionarios && (
          <>
            {mode !== "delegar" && <hr />}
            <Collapse
              bordered={false}
              collapsible="icon"
              defaultActiveKey={currentQuestionarios?.areas?.map(
                (area: any, index: number) => index + "area"
              )}
              items={currentQuestionarios?.areas?.map(
                (area: any, index: number) => {
                  return {
                    key: index + "area",
                    label: moduloProcessos.areas?.filter(
                      (a: any) => a._id === area
                    )[0].description,

                    children: (
                      <Collapse
                        bordered={false}
                        collapsible="icon"
                        items={currentQuestionarios.capitulos
                          ?.filter((c: any) => {
                            if (c.areas_id && c.areas_id[0]) {
                              return c.areas_id.some(
                                (areaId: any) => areaId === area
                              );
                            } else {
                              return c.tags?.some(
                                (areaId: any) => areaId === area
                              );
                            }
                          })
                          ?.sort(
                            (a: any, b: any) =>
                              new Date(a.created_at).getTime() -
                              new Date(b.created_at).getTime()
                          )
                          ?.filter((c: any) => !c.inativo)
                          ?.map((cap: any, index: number) => {
                            return {
                              key: index + "cap",
                              label: cap.description,
                              children: (
                                <>
                                  {currentQuestionarios.perguntasRelacao
                                    ?.filter(
                                      (p: any) =>
                                        p.capitulo_id.toString() ===
                                          cap._id.toString() && !p.inativo
                                    )
                                    ?.filter((p: any) =>
                                      moduloProcessos.perguntas?.find(
                                        (perg: any) =>
                                          perg._id === p.pergunta_id
                                      )
                                    )
                                    .map((p: any, indexPergunta: number) => (
                                      <p className="d-flex j-b a-c perguntas-opt">
                                        {`${indexPergunta + 1}. ${
                                          moduloProcessos.perguntas?.filter(
                                            (perg: any) =>
                                              perg._id.toString() ===
                                              p.pergunta_id.toString()
                                          )[0]?.description
                                        }`}
                                        {userInfo?.grupos?.find(
                                          (gp: any) => gp.name === "assinante"
                                        ) && (
                                          <DeleteOutlined
                                            onClick={() => {
                                              removerPergunta(p);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              color: "red",
                                            }}
                                          />
                                        )}
                                      </p>
                                    ))}
                                  <Collapse
                                    collapsible="icon"
                                    items={moduloProcessos.capitulos
                                      ?.filter(
                                        (c: any) => c.dependencia === cap._id
                                      )
                                      .map((c: any, index: number) => {
                                        return {
                                          key: index + "subprocesso",
                                          label: c.description,
                                          children: (
                                            <>
                                              {moduloProcessos.capituloPerguntas
                                                ?.filter(
                                                  (p: any) =>
                                                    p.capitulo_id.toString() ===
                                                      c._id.toString() &&
                                                    !p.inativo
                                                )
                                                .filter((p: any) =>
                                                  moduloProcessos.perguntas?.find(
                                                    (perg: any) =>
                                                      perg._id ===
                                                        p.pergunta_id &&
                                                      !perg.inativo
                                                  )
                                                )
                                                .map(
                                                  (
                                                    p: any,
                                                    indexPergunta: number
                                                  ) => (
                                                    <p className="d-flex j-b a-c perguntas-opt">
                                                      {`${indexPergunta + 1}. ${
                                                        moduloProcessos.perguntas?.filter(
                                                          (perg: any) =>
                                                            perg._id.toString() ===
                                                            p.pergunta_id.toString()
                                                        )[0]?.description
                                                      }`}
                                                      {userInfo?.grupos?.find(
                                                        (gp: any) =>
                                                          gp.name ===
                                                          "assinante"
                                                      ) && (
                                                        <DeleteOutlined
                                                          onClick={() => {
                                                            removerPergunta(p);
                                                          }}
                                                          style={{
                                                            cursor: "pointer",
                                                            color: "red",
                                                          }}
                                                        />
                                                      )}
                                                    </p>
                                                  )
                                                )}
                                            </>
                                          ),
                                        };
                                      })}
                                  />
                                </>
                              ),
                              extra: (
                                <div className="d-flex j-b a-c">
                                  {params.qid && mode === "delegar" && (
                                    <>
                                      <Select
                                        placeholder={
                                          mode === "delegar"
                                            ? "Delegar apenas este processo"
                                            : "Responsáveis do processo"
                                        }
                                        disabled={
                                          usuarios?.find(
                                            (usr: any) =>
                                              usr.funcoesDetalhadas?.find(
                                                (fd: any) =>
                                                  (fd.funcao_id?._id ===
                                                    "65fb2140c0e72d990258afc0" &&
                                                    fd.reference_model_id ===
                                                      params.qid) || // responder-questionario
                                                  (fd.funcao_id?._id ===
                                                    "67599007713e52e267417c65" && // responder-versao
                                                    fd.reference_model_id ===
                                                      versaoId)
                                              ) &&
                                              usr.funcoesDetalhadas?.find(
                                                (fd: any) =>
                                                  fd.funcao_id?._id ===
                                                    "65f982dcc0e72d990258afad" &&
                                                  fd.reference_model_id === area // view-area
                                              )
                                          )?._id || liderAreas?.[0]
                                            ? true
                                            : false
                                        }
                                        value={usuarios
                                          ?.filter(
                                            (usr: any) =>
                                              usr.funcoesDetalhadas?.find(
                                                (fd: any) =>
                                                  (fd.funcao_id?._id ===
                                                    "65fb2140c0e72d990258afc0" &&
                                                    fd.reference_model_id ===
                                                      params.qid) || // responder-questionario
                                                  (fd.funcao_id?._id ===
                                                    "67599007713e52e267417c65" && // responder-versao
                                                    fd.reference_model_id ===
                                                      versaoId)
                                              ) &&
                                              usr.funcoesDetalhadas?.find(
                                                (fd: any) =>
                                                  fd.funcao_id?._id ===
                                                    "65f8a0c1c0e72d990258afa4" &&
                                                  fd.reference_model_id ===
                                                    cap._id // responder-processo
                                              )
                                          )
                                          .map((usr: any) => {
                                            return {
                                              label: usr.name,
                                              value: usr._id,
                                            };
                                          })}
                                        onChange={(v) => {
                                          setLiderProcessos((prev) => [
                                            ...(prev || []).filter(
                                              (obj) =>
                                                obj.processo_id !== cap._id
                                            ),
                                            {
                                              processo_id: cap._id,
                                              users: v,
                                            },
                                          ]);
                                        }}
                                        onClear={() => {
                                          setLiderProcessos((prev) => [
                                            ...(prev || []).filter(
                                              (obj) =>
                                                obj.processo_id !== cap._id
                                            ),
                                          ]);
                                        }}
                                        allowClear
                                        // mode="multiple"
                                        showSearch
                                        optionFilterProp="label"
                                        filterOption={(
                                          input: string,
                                          option: any
                                        ) => {
                                          return (
                                            option.label
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          );
                                        }}
                                        // maxTagCount={"responsive"}
                                        style={{
                                          // minWidth: "13rem",
                                          // maxWidth: "18rem",
                                          width: "18rem",
                                        }}
                                      >
                                        {usuarios
                                          ?.filter((u: any) =>
                                            params.id || currentCliente
                                              ? u.cliente_id === params.id ||
                                                u.cliente_id ===
                                                  currentCliente._id
                                              : u.cliente_id
                                          )
                                          .map((user: any) => (
                                            <Option
                                              key={user._id}
                                              value={user._id}
                                              label={user.name}
                                            >
                                              {user.name}
                                            </Option>
                                          ))}
                                      </Select>
                                    </>
                                  )}
                                  {mode !== "delegar" && (
                                    <>
                                      <Tooltip title="Adicionar pergunta">
                                        <PlusOutlined
                                          onClick={() =>
                                            addPerg(
                                              currentQuestionarios,
                                              area,
                                              cap
                                            )
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "blue",
                                          }}
                                        />
                                      </Tooltip>

                                      <DeleteOutlined
                                        onClick={() => removerCap(cap)}
                                        style={{
                                          cursor: "pointer",
                                          color: "red",
                                          marginRight: "2rem",
                                          marginLeft: "1rem",
                                        }}
                                      />
                                    </>
                                  )}
                                </div>
                              ),
                            };
                          })}
                      />
                    ),
                    extra: (
                      <div className="d-flex j-b a-c">
                        {params.qid && mode === "delegar" && (
                          <>
                            <Select
                              placeholder={
                                mode === "delegar"
                                  ? "Delegar todo questionário"
                                  : "Responsável da área"
                              }
                              disabled={
                                usuarios?.filter(
                                  (usr: any) =>
                                    usr.funcoesDetalhadas?.find(
                                      (fd: any) =>
                                        (fd.funcao_id?._id ===
                                          "65fb2140c0e72d990258afc0" &&
                                          fd.reference_model_id ===
                                            params.qid) || // responder-questionario
                                        (fd.funcao_id?._id ===
                                          "67599007713e52e267417c65" && // responder-versao
                                          fd.reference_model_id === versaoId)
                                    ) &&
                                    usr.funcoesDetalhadas?.find(
                                      (fd: any) =>
                                        fd.funcao_id?._id ===
                                          "65f8a0c1c0e72d990258afa4" &&
                                        currentQuestionarios.capitulos
                                          .filter((c: any) => {
                                            if (c.areas_id && c.areas_id[0]) {
                                              return c.areas_id.some(
                                                (areaId: any) => areaId === area
                                              );
                                            } else {
                                              return c.tags?.some(
                                                (areaId: any) => areaId === area
                                              );
                                            }
                                          })
                                          .filter((c: any) => !c.inativo)
                                          ?.map((c: any) => c._id)
                                          ?.includes(fd.reference_model_id)
                                    )
                                )?.[0] ||
                                liderProcessos?.find(
                                  (item) =>
                                    currentQuestionarios.capitulos
                                      ?.filter((c: any) => {
                                        if (c.areas_id && c.areas_id[0]) {
                                          return c.areas_id.some(
                                            (areaId: any) => areaId === area
                                          );
                                        } else {
                                          return c.tags?.some(
                                            (areaId: any) => areaId === area
                                          );
                                        }
                                      })
                                      ?.filter((c: any) => !c.inativo)
                                      ?.find(
                                        (c: any) => c._id === item.processo_id
                                      )?._id === item.processo_id &&
                                    item.users?.[0]
                                )
                                  ? true
                                  : false
                              }
                              value={
                                usuarios?.find(
                                  (usr: any) =>
                                    usr.funcoesDetalhadas?.find(
                                      (fd: any) =>
                                        (fd.funcao_id?._id ===
                                          "65fb2140c0e72d990258afc0" &&
                                          fd.reference_model_id ===
                                            params.qid) || // responder-questionario
                                        (fd.funcao_id?._id ===
                                          "67599007713e52e267417c65" && // responder-versao
                                          fd.reference_model_id === versaoId)
                                    ) &&
                                    usr.funcoesDetalhadas?.find(
                                      (fd: any) =>
                                        fd.funcao_id?._id ===
                                          "65f982dcc0e72d990258afad" &&
                                        fd.reference_model_id === area // view-area
                                    )
                                )?._id
                              }
                              onChange={(v) => {
                                if (v) {
                                  setLiderAreas((prev: any) => [
                                    ...prev.filter(
                                      (liderArea: any) =>
                                        liderArea.user_id !== v &&
                                        liderArea.area_id !== area &&
                                        liderArea.questionario_id !==
                                          currentQuestionarios._id &&
                                        liderArea.questionario_nome !==
                                          currentQuestionarios.nome
                                    ),
                                    {
                                      user_id: v,
                                      area_id: area,
                                      questionario_id: currentQuestionarios._id,
                                      questionario_nome:
                                        currentQuestionarios.nome,
                                    },
                                  ]);
                                }
                              }}
                              onClear={() => {
                                setLiderAreas((prev: any) => [
                                  ...prev.filter(
                                    (item: any) => item.area_id !== area
                                  ),
                                ]);
                              }}
                              allowClear
                              showSearch
                              optionFilterProp="label"
                              filterOption={(input: string, option: any) => {
                                return (
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                              style={{
                                minWidth: "13rem",
                              }}
                            >
                              {usuarios
                                ?.filter((u: any) =>
                                  params.id || currentCliente
                                    ? u.cliente_id === params.id ||
                                      u.cliente_id === currentCliente._id
                                    : u.cliente_id
                                )
                                .map((user: any) => (
                                  <Option
                                    key={user._id}
                                    value={user._id}
                                    label={user.name}
                                  >
                                    {user.name}
                                  </Option>
                                ))}
                            </Select>
                          </>
                        )}
                        {userInfo?.grupos?.find(
                          (gp: any) => gp.name === "assinante"
                        ) &&
                          mode !== "delegar" && (
                            <div className="d-flex j-b a-c">
                              <div className="d-flex j-b a-c">
                                <Tooltip title="Adicionar capitulo">
                                  <PlusOutlined
                                    onClick={() =>
                                      addCap(currentQuestionarios, area)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      color: "blue",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                              <DeleteOutlined
                                onClick={() => removerArea(area)}
                                style={{
                                  cursor: "pointer",
                                  color: "red",
                                  marginLeft: "1rem",
                                }}
                              />
                            </div>
                          )}
                      </div>
                    ),
                  };
                }
              )}
            />
            {mode === "delegar" ? (
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Button
                  style={{
                    marginTop: "2rem",
                    marginBottom: "-1rem",
                  }}
                  type="primary"
                  icon={<SaveOutlined />}
                  htmlType="submit"
                >
                  Salvar respondentes
                </Button>
              </div>
            ) : (
              <SaveBtn type="submit" text="Salvar progresso" />
            )}
          </>
        )}
      </Form>

      <Modal
        title="Adicionar capitulo"
        open={isModalOpen}
        footer={[
          <Button type="default" form="form-pergunta" onClick={handleCancel}>
            Cancelar
          </Button>,
        ]}
        onCancel={handleCancel}
      >
        <div className="clientes__actions">
          <Search
            placeholder="Buscar capitulo"
            className="clientes__actions--search"
            allowClear
            enterButton="Buscar"
            style={{ marginRight: "5rem" }}
            onSearch={onSearchCap}
          />
        </div>

        <Table
          className="clientes__list"
          dataSource={moduloProcessos.capitulos?.filter(
            (c: any) =>
              c.description.includes(searchCap) &&
              !selectedQuest?.questionario?.capitulos?.includes(c)
          )}
          style={{ cursor: "pointer" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                let newQuest = selectedQuest.questionario;
                let newCap = record;
                let newPerguntasRelacao: any = [];

                newCap.areas_id = [selectedQuest.area];

                newQuest.capitulos = [...newQuest.capitulos, record];

                moduloProcessos.capituloPerguntas
                  ?.filter(
                    (relCapPerg: any) => relCapPerg.capitulo_id === newCap._id
                  )
                  .forEach((relCapPerg: any) => {
                    newPerguntasRelacao.push({
                      capitulo_id: newCap._id,
                      pergunta_id: relCapPerg.pergunta_id,
                    });
                  });

                newQuest.perguntasRelacao = [
                  ...newQuest.perguntasRelacao,
                  ...newPerguntasRelacao,
                ];

                setCurrentQuestionarios(newQuest);

                handleCancel();
                setSelectedQuest({});
              },
            };
          }}
          columns={columnsCap}
        />
      </Modal>

      <Modal
        title="Adicionar pergunta"
        open={isModalOpenPerg}
        footer={[
          <Button type="default" form="form-pergunta" onClick={handleCancel}>
            Cancelar
          </Button>,
          // <Button type="primary">Adicionar</Button>,
        ]}
        onCancel={handleCancel}
      >
        <div className="clientes__actions">
          <Search
            placeholder="Buscar pergunta"
            className="clientes__actions--search"
            allowClear
            enterButton="Buscar"
            style={{ marginRight: "5rem" }}
            onSearch={onSearchPerg}
          />
        </div>

        <Table
          className="clientes__list"
          columns={columnsPerg}
          dataSource={moduloProcessos.perguntas?.filter((p: any) =>
            p.description?.toUpperCase().includes(searchPerg.toUpperCase())
          )}
          pagination={{ pageSize: 5 }}
          locale={{ emptyText: "Nenhuma pergunta localizada" }}
          style={{ cursor: "pointer" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                let newQuest = selectedQuest.questionario;

                let idPergunta = newQuest.perguntasRelacao.filter(
                  (p: any) =>
                    p.capitulo_id === selectedQuest.capitulo._id &&
                    p.pergunta_id === record._id
                )[0];

                newQuest.perguntasRelacao = [
                  ...newQuest.perguntasRelacao,
                  {
                    _id: idPergunta ? idPergunta._id : null,
                    capitulo_id: selectedQuest.capitulo._id,
                    pergunta_id: record._id,
                    inativo: newQuest.perguntasRelacao.filter(
                      (p: any) =>
                        p.capitulo_id === selectedQuest.capitulo._id &&
                        p.pergunta_id === record._id
                    )[0]
                      ? false
                      : false,
                  },
                ];

                setCurrentQuestionarios(newQuest);

                handleCancel();
                setSelectedQuest({});
              },
            };
          }}
        />
      </Modal>
    </div>
  );
};

export default EditTemplate;
